import { graphql, useStaticQuery } from "gatsby";
import Button from "../../common/Button/Button";
import RichContent from "@components/common/RichContent/RichContent";
import { useCookieData } from "../../../hooks/useCookieData";

import React, { useEffect } from "react";
import css from "./CookieBar.module.scss";

import { EmptyRender, doNotRenderIfEmpty, useLocalizedData } from '@components/util/LanguageHooks';

import { getFirstItemFromQuery } from "@util/mapper";


const CookieBarQuery = (props) => {
    const { pim } = useStaticQuery(graphql`
        fragment CookieBarFragment on PIM_CookiepolicyConnection {
            edges {
                    node {
                    cookiepopupLink
                    cookiepopupConfirm
                    cookiebalkTxt
                    cookieSettings
                    cookieInfo
                    cookieAccept
                    }
                }
            }
        query {
            pim {
                getCookiepolicyListing_nl_BE: getCookiepolicyListing
                (defaultLanguage: "nl_BE") {
                    ...CookieBarFragment
                }
                getCookiepolicyListing_en: getCookiepolicyListing
                (defaultLanguage: "en") {
                    ...CookieBarFragment
                }
            }
        }
    `);

    function setConsentOn() {
        const now = new Date()
        const halfyear = 15770000000000
        const item = {
            value: true,
            expiry: now.getTime() + halfyear,
        }
        localStorage.setItem("cookiesConsented", JSON.stringify(item))
    }

    const cookieBarItemsQuery = useLocalizedData(pim, 'getCookiepolicyListing');
    const cookieBarItems = getFirstItemFromQuery(cookieBarItemsQuery);

    const { cookieData, update } = useCookieData()
    useEffect(() => {
        update({ buttonText: cookieBarItems.cookieSettings, openCookiePopupFunction: props.openCookiePopup, closeCookiebarFunction: props.closeCookieBar })
    }, []);

    if (CookieBarQuery.doNotRender) { return <EmptyRender />; }

    return (
        <div className={`${css.cookieDiv}`}>
            <div className={`${css.cookieTextDiv}`}>
                <RichContent className={`${css.cookieText}`}>{cookieBarItems.cookiebalkTxt}</RichContent><a onClick={() => { props.openCookiePopup(); props.closeCookieBar() }} className={`${css.cookieLink}`} href="#">{cookieBarItems.cookieInfo}</a>
            </div>
            <div className={`${css.cookieButtons}`}>
                <div className={`${css.cookieButton}`}>
                    <Button
                        variant="blue"
                        onClick={() => { props.closeCookieBar(); typeof window !== "undefined" && setConsentOn() }}
                        text={cookieBarItems.cookieAccept}
                        uppercase={false} /></div>
                <div className={`${css.cookieButton}`}><Button
                    onClick={() => { props.openCookiePopup(); props.closeCookieBar() }}
                    variant="white"
                    text={cookieBarItems.cookieSettings}
                    uppercase={false} /></div>
            </div>
        </div>
    );
};

export default CookieBarQuery;
