import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import ColumnGroup from "@components/common/ColumnGroup/ColumnGroup";
import Column from "@components/common/Column/Column";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import RichContent from "@components/common/RichContent/RichContent";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import { graphql } from "gatsby";
import React, { Fragment } from "react";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import css from "@styles-pages/index.module.scss";
import labelcss from "@styles/label.module.scss";

import { SeoMetaTags } from "../components/util/SOE/SOE";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getProjectnew',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const Page = (props) => {

    const { data } = props;

    const {
        projectpagetitle,
        projectintroduction,
        backgroundimage,
        projectintropage,
        column1txt,
        column2pics,
        column3txt,
        column4txt,
        outro,
        stucturedData,
        Tags: labels
    } = data.pim.getProjectnew;

    return (
        <Fragment>
            <SeoMetaTags pim={data.pim} metadata={metadata} structureddata={stucturedData} />
            <ContentWrapper isWide={true}>
                <Header image bgImage={backgroundimage} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading isSemantic={true} variant="h1" color="white">
                                {projectpagetitle}
                            </Heading>
                            <HighlightedText color="white">
                                {projectintroduction}
                            </HighlightedText>
                            {labels && labels.length > 0 && (
                                <div className={labelcss.labelDiv}>
                                    <p className={labelcss.labelsText}>Tags: </p>
                                    {labels.map((label) => (
                                        <div key={label.id} className={labelcss.whiteLabel}>
                                            {label.Tag}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>

                <ContentWrapper paddedTop paddedBottom>
                    <ColumnGroup halign="halignbetween">
                        <Column widthmd="widthsm100">
                            <ContentWrapper isWide paddedTop paddedBottom>
                                <RichContent>{projectintropage}</RichContent>
                            </ContentWrapper>
                        </Column>
                        <Column widthmd="widthsm100" widthlg="widthmd45">
                            <ContentWrapper isWide paddedBottom>
                                <RichContent>{column1txt}</RichContent>
                            </ContentWrapper>
                        </Column>
                        <Column widthmd="widthsm100" widthlg="widthmd45">
                            <ContentWrapper isWide paddedBottom>
                                <img alt="fluidimg" loading="lazy" class="img-fluid" src={generateThumbnailUrl(column2pics, "general_thumbnail")} />
                            </ContentWrapper>
                        </Column>
                        <Column widthmd="widthsm100" widthlg="widthmd45">
                            <ContentWrapper isWide paddedBottom>
                                <RichContent>{column3txt}</RichContent>
                            </ContentWrapper>
                        </Column>
                        <Column widthmd="widthsm100" widthlg="widthmd45">
                            <ContentWrapper isWide paddedBottom>
                                <RichContent>{column4txt}</RichContent>
                            </ContentWrapper>
                        </Column>
                        <Column widthmd="widthsm100">
                            <ContentWrapper isWide paddedBottom>
                                <RichContent>{outro}</RichContent>
                            </ContentWrapper>
                        </Column>
                    </ColumnGroup>
                </ContentWrapper>
            </ContentWrapper>
        </Fragment>
    );
};

export default Page;

export const query = graphql`
    query($id: Int!, $localeLang: String!) {
        pim {
            getProjectnew(id: $id, defaultLanguage: $localeLang) {
                projectpagetitle
                backgroundimage {
                    id
                    fullpath
                }
                projectintroduction
                projectintropage
                column1txt
                column2pics {
                    id
                    fullpath
                }
                column3txt
                column4txt
                outro

                seotitle
                seodescription
                stucturedData
                ogimage {
                id
                fullpath
                }
                Tags {
                    ... on PIM_object_Tag {
                        id
                        Tag
                    }
                }
            }
        }
    }
`;
