import { graphql, useStaticQuery } from "gatsby";
import { LocalizedLink as Link } from "@components/util/LanguageProvider";

import React, { useContext, useEffect, useState } from "react";
import Dropdown from "../../../../common/Dropdown/Dropdown";
import css from "./Navigation.module.scss";

import { setIsMobileNavigationOpen as setIsMobileNavigationOpenAction } from "@redux/actions";
import { setIsStickyNavigationOpen as setIsStickyNavigationOpenAction } from "@redux/actions";
import { connect } from "react-redux";

import ContactButtons from "../ContactButtons/ContactButtons";

import classNames from "classnames";

import { useLocalizedData } from '@components/util/LanguageHooks';

import { getFirstItemFromQuery } from "@util/mapper";
import { LanguageContext } from "@components/util/LanguageProvider";
import { urlBuilder } from "@util/url-builder";
import { useLocaleContext } from "@components/util/LanguageProvider";

import { useUrlBuilder } from "@util/url-builder";
import { use } from "react";

const NavigationQuery = (props) => {
    const { pim } = useStaticQuery(graphql`
        fragment NavigationFragment on PIM_HeaderConnection {
                    edges {
                        node {
                            LanguageSwitch
                            NavContent {
                                ... on PIM_object_Link {
                                    id
                                    Link
                                    Name
                                    DropDownLinks {
                                        ... on PIM_object_Link { 
                                            Link
                                            Name
                                        }
                                        }
                                    PageConfig {
                                        ... on PIM_object_ServicesConfig {
                                            classname
                                            title
                                            OtherservicesURL
                                            OtherServicesNavTitle
                                            id
                                            featuredservices {
                                                ... on PIM_object_FeaturedService {
                                                    URL
                                                    id
                                                    title
                                                    listingTitle
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
        }
        query{
            pim{
                getHeaderListing_nl_BE: getHeaderListing(defaultLanguage: "nl_BE") {
                    ...NavigationFragment
                }
                getHeaderListing_en: getHeaderListing(defaultLanguage: "en") {
                    ...NavigationFragment
                }
            }
        }
    `);

    const data = useLocaleContext();
    const navItemsQuery = useLocalizedData(pim, 'getHeaderListing');
    let navItems = getFirstItemFromQuery(navItemsQuery);

    const LanguageSwitch = navItems.LanguageSwitch;

    navItems = navItems.NavContent;

    const contextLang = useContext(LanguageContext);
    const pageIdOutput = contextLang.pageId;
    
    var browserLanguage = "";
    if ("undefined" !== typeof window) {
        browserLanguage = navigator.language || navigator.userLanguage;
        browserLanguage = browserLanguage.slice(0, 2);

        if (!localStorage.getItem('userLang')) {
            localStorage.setItem('userLang', "");
        }

        if (!localStorage.getItem('browserLanguage')) {
            localStorage.setItem('browserLanguage', browserLanguage);
        }
    }


    const [preferredLanguage, setPreferredLanguage] = useState(null);

    useEffect(() => {
        const userLang = localStorage.getItem('userLang');
        const browserLanguage = localStorage.getItem('browserLanguage');

        if (userLang) {
            setPreferredLanguage(userLang);
        } else if (browserLanguage) {
            setPreferredLanguage(browserLanguage);
        }
    }, []);

    // TODO: redirect logic

    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    const { buildLocalizedStaticUrl } = useUrlBuilder();
    const { locale } = useLocaleContext();
    const [siteLanguage, setSiteLanguage] = useState("");

    useEffect(() => {
        if (locale === 'nl_BE') {
            setSiteLanguage('NL');
            setPreferredLanguage('nl');
            localStorage.setItem('userLang', "nl");
        } else if (locale === 'en') {
            setSiteLanguage('EN');
            setPreferredLanguage('en');
            localStorage.setItem('userLang', "en");
        }
    }, [locale]);

    const languageOrder = siteLanguage === 'NL' ? ['nl_BE', 'en'] : ['en', 'nl_BE'];

    return (
        <nav>
            <ul>
                    {LanguageSwitch && languageOrder.map((language) => {
                        const url = urlBuilder.buildDynamicUrl(pageIdOutput, language);
                        const formatedUrl = url.slice(0, -1);
                        let langText = "";

                        if (language === "nl_BE") {
                            langText = "NL";

                        }
                        else {
                            langText = "EN"
                        }

                        return (
                            <li key={language}
                                className={`${css.link} ${css.big} ${css.mobile}`}
                                id={language}
                            >
                                <Link to={formatedUrl}
                                    onClick={() => {
                                        props.setIsMobileNavigationOpen(false);
                                    }}>
                                    <span>{langText}</span>
                                </Link>
                            </li>)
                    })}
                <li className={`${css.link} ${css.big} ${css.mobile}`}>
                    <Link
                        onClick={() => {
                            props.setIsMobileNavigationOpen(false);
                        }}
                        to="/"
                    >
                        <span>Home</span>
                    </Link>
                </li>

                {navItems.map((item) => (
                    <li key={item.id} className={`${css.link} ${css.big}`}>
                        {item.DropDownLinks === null ? (
                            <Link
                                onClick={() => {
                                    props.setIsMobileNavigationOpen(false);
                                }}
                                to={buildLocalizedStaticUrl(item.Link)}
                                activeClassName="active"
                            >
                                <span>{item.Name}</span>
                            </Link>
                        ) : (
                            <>
                                <ul className={css.mobile}>
                                    <li className={`${css.big}`}>
                                        <Link
                                            onClick={() => {
                                                props.setIsMobileNavigationOpen(
                                                    false
                                                );
                                            }}
                                            to={buildLocalizedStaticUrl(item.Link)}
                                        >
                                            <span>{item.Name}</span>
                                        </Link>
                                    </li>
                                    {item.DropDownLinks.map((dropdownItem) => (
                                        <li
                                            key={dropdownItem.id}
                                            className={`${css.dropdownLink}`}
                                        >
                                            <Link
                                                onClick={() => {
                                                    props.setIsMobileNavigationOpen(
                                                        false
                                                    );
                                                }}
                                                to={buildLocalizedStaticUrl(dropdownItem.Link)}
                                            >
                                                <span>
                                                    {dropdownItem.Name}
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                                <div className={css.desktop}>
                                    <Dropdown
                                        dropdownInfo={item.DropDownLinks}
                                        headerTitle={item.Name}
                                        // headerUrl={item.Link}
                                        headerUrl={buildLocalizedStaticUrl(item.Link)}
                                    ></Dropdown>
                                </div>
                            </>
                        )}
                    </li>
                ))}
                <li className={classNames(css.mobile, css.big)}>
                    <ContactButtons />
                </li>
                {LanguageSwitch && (
                    <li className={classNames(css.dekstop, css.big, css.link, css.switch, css.desktopOnly)}>
                        <ul id="lang-wrapper" className={css.languageWrapper}>
                            {languageOrder.map((language, index) => {
                                const url = urlBuilder.buildDynamicUrl(pageIdOutput, language);
                                const formatedUrl = url.slice(0, -1);
                                let langText = "";

                                if (language === "nl_BE") {
                                    langText = "NL";
                                }
                                else {
                                    langText = "EN"
                                }
                                if (index === 1 && !isDropdownVisible) {
                                    return null;
                                }

                                return (
                                    <li key={language}
                                        className={classNames(css.langSelectText)}
                                        id={language}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <Link className={css.langSelectText} to={formatedUrl} on>
                                            {langText}
                                        </Link>
                                    </li>)
                            })}
                        </ul>
                    </li>
                )}
            </ul>
        </nav>
    );
};


const mapDispatchToProps = (dispatch) => ({
    setIsMobileNavigationOpen: (boolean) =>
        dispatch(setIsMobileNavigationOpenAction(boolean)),
    setIsStickyNavigationOpen: (boolean) =>
        dispatch(setIsStickyNavigationOpenAction(boolean)),
});

const mapStateToProps = (globalState) => ({
    isMobileNavigationOpen: globalState.isMobileNavigationOpen,
    isStickyNavigationOpen: globalState.isStickyNavigationOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationQuery);
