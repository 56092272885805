import css from "@styles-pages/magento.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import urlBuilder from "@util/url-builder";

import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import InfoBoxGrid from "@components/common/InfoBox/InfoBoxGrid";
import InfoBoxItem from "@components/common/InfoBox/InfoBoxItem";
import RichContent from "@components/common/RichContent/RichContent";

import { SeoMetaTags } from "../components/util/SOE/SOE";

import { useEnableFlag } from '@components/util/LanguageHooks';

/* eslint-disable no-undef */
export const metadata = {
    query: 'getWebinarConfigListing',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const WebinarsQuery = (props) => {

    const { data } = props;
    const pim = data.pim;


    const filteredGetWebinarConfigListing = useEnableFlag(pim.getWebinarConfigListing);




    const {
        PageTitle,
        PageSubTitle,
        noWebinars,
        webinars,
        stucturedData
    } = filteredGetWebinarConfigListing.edges[0].node;

    return (
        <>
            <SeoMetaTags pim={pim} metadata={metadata} structureddata={stucturedData}/>
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                {PageTitle}
                            </Heading>
                            <HighlightedText color="white">
                                {PageSubTitle}
                            </HighlightedText>
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                <ContentWrapper isWide={true} paddedTopLarge>
                    <ContentWrapper>
                        <InfoBoxGrid>
                            {!webinars && (
                                <div class="alert alert-warning">
                                    <RichContent>{noWebinars}</RichContent>
                                </div>
                            )}
                            {webinars?.map((webinar, index) => (
                                <InfoBoxItem
                                    key={index}
                                    col="colThree"
                                    bgColor="grey"
                                    infoTitle={webinar.PageTitle}
                                    infoText={webinar.PageSubTitle}
                                    infoDate={webinar.DateIndication}
                                    url={urlBuilder.buildDynamicUrl(
                                        webinar.id
                                    )}
                                    />
                            ))}
                        </InfoBoxGrid>
                    </ContentWrapper>
                </ContentWrapper>
            </ContentWrapper>
        </>
    );
};

export default WebinarsQuery;

export const query = graphql`
    query($localeLang: String!){
            pim {
          getWebinarConfigListing(defaultLanguage: $localeLang) {
            edges {
              node {
                PageTitle
                PageSubTitle
                noWebinars
                webinars {
                  ... on PIM_object_Webinar {
                    englishVersion
                    id
                    PageTitle
                    DateIndication
                    PageSubTitle
                  }
                }
                seotitle
                seodescription
                stucturedData
                ogimage {
                  id
                  fullpath
                }
              }
            }
          }
        }
      }`;