import React, { Fragment } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { __ } from "@i18n/util";
import css from "@styles-pages/contact.module.scss";

import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import ContentWrapper from "../components/common/ContentWrapper/ContentWrapper";
import Header from "../components/common/Header/Header";
import Heading from "../components/common/Heading/Heading";
import ContactInformation from "../components/contact/ContactInformation/ContactInformation";
import { SeoMetaTags } from "../components/util/SOE/SOE";
import ContactForm from "../components/contact/ContactForm/ContactForm";

import { useEnableFlag } from '@components/util/LanguageHooks';

/* eslint-disable no-undef */
export const metadata = {
    query: 'getContactConfig',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const VacancyContactQuery = (props) => {

    const {data} = props;
    const pim = data.pim;

    const filteredGetVacancyListing = useEnableFlag(pim.getVacancyListing);

    const { title, subtitle, email, phonenumber, stucturedData } = pim.getContactConfig;
    const vacancies = filteredGetVacancyListing.edges
    const mailtovalue = "mailto:" + email;
    const phonevalue = "tel:" + phonenumber;

    return (
        <Fragment>
            <SeoMetaTags pim={pim} metadata={metadata} structureddata={stucturedData} />
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading isSemantic={true} variant="h1" color="white">
                                {title}
                            </Heading>
                            <HighlightedText color="white">
                                {subtitle}
                            </HighlightedText>
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                <ContentWrapper paddedTop={true} paddedBottom={true}>
                    <ContactInformation
                        items={[
                            {
                                label: __("Phone"),
                                text: phonenumber,
                                value: phonevalue,
                            },
                            {
                                label: __("E-mail"),
                                text: email,
                                value: mailtovalue,
                            },
                        ]}
                    />

                    <ContactForm jobVacancies={vacancies} variant="vacancyContact" isVacancy endpoint={`${process.env.PIMCORE_BASE_URL}${process.env.PIMCORE_CONTACT_ENDPOINT}`} />
                </ContentWrapper>
            </ContentWrapper>
        </Fragment>
    );
};

export default VacancyContactQuery;

export const query = graphql`
    query($localeLang: String!){
        pim {
            getContactConfig(id: 162, defaultLanguage: $localeLang) {
            phonenumber
            seotitle
            seodescription
            subtitle
            title
            email
            stucturedData
            }
            getVacancyListing {
            edges {
                node {
                    englishVersion
                    functiontitle
                }
            }
            }
        }
    }`;