/* eslint eqeqeq: 0 */

import React, { Fragment, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { __ } from "@i18n/util";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import { SeoMetaTags } from "../components/util/SOE/SOE";

import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import Fade from '@components/util/Fade';

import labelcss from "@styles/label.module.scss";
import css from "@components/common/Partners/Partners.module.scss"
import classNames from "classnames";

import PartnerGrid from "@components/common/Partners/PartnerGrid";
import RichContent from "@components/common/RichContent/RichContent";
import { Link } from "gatsby";
import { getFirstItemFromQuery } from "@util/mapper";

import AnchorLink from "react-anchor-link-smooth-scroll";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getPartnersConfigListing',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const Partners = (props) => {

    const { data } = props;
    const pim = data.pim;

    const getListsFromPartnerConfig = (config, amount, keyword) => {
        const result = [];
        for (let i = 1; i <= amount; i++) {
            result.push(getFirstItemFromQuery(config)[`${keyword}${i}`]);
        }
        return result;
    }

    const lists = getListsFromPartnerConfig(pim.getPartnersConfigListing, 5, "list");
    const labels = getListsFromPartnerConfig(pim.getPartnersConfigListing, 5, "labelText");
    const listTitles = getListsFromPartnerConfig(pim.getPartnersConfigListing, 5, "listTitle");

    const overviewPartners = getFirstItemFromQuery(pim.getPartnersConfigListing);

    return (
        <Fragment>
            <SeoMetaTags pim={pim} metadata={metadata} />
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        vertical="center"
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                {overviewPartners.overviewTitle}
                            </Heading>
                            <HighlightedText color="white">
                                {overviewPartners.overviewIntro}
                            </HighlightedText>
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
            </ContentWrapper>

            <Fade duration={2000}>
                <ContentWrapper maxWidth="maxWidthLarge" paddedTop={true} >
                    <div className={classNames(css.labelContainer)}>
                        <div className={classNames(css.labelIntro)}>{__("Discover our partners by type")}</div>
                        <div className={classNames(css.tagsWrapper)}>

                            {labels.map((label, index) => {

                                if (!label || !lists[index]) {
                                    return null;
                                }
                                return (

                                    <AnchorLink offset='25' href={`#${label}`} key={index} className={classNames(labelcss.label, labelcss.petrol, css.linkLabel)} >
                                        {label}
                                    </AnchorLink>

                                )
                            })}

                        </div>
                    </div>

                </ContentWrapper>
            </Fade>

            {lists.map((list, index) => {

                if (!list) {
                    return null;
                }

                return (
                    <><Fade duration={2000}>
                        <ContentWrapper maxWidth="maxWidthLarge" paddedTop={true} paddedBottom={true}>
                            <h2 id={`${labels[index]}`} className={classNames(css.partnerTitle)}>{`${listTitles[index]}`}</h2>
                            <PartnerGrid partners={list} key={index} />
                        </ContentWrapper>
                    </Fade>
                    </>
                )
            })}

        </Fragment>
    );
};



export default Partners;

export const query = graphql`
    fragment PartnerFragment on PIM_object_TechnologyLink {
        id
        techLogo{
            id
            fullpath
        }
        techName
        partnerTier
        partnerIntro
        partnerShortDescription
        partnerContent
        partnerPageUrl
        techURL
        techNewtab
        seotitle
    }
    query($localeLang: String!) {
            pim {
                getPartnersConfigListing(defaultLanguage: $localeLang, filter: "{\"UniqueRef\": \"partnersConfig\"}")  {
                    edges{
                        node{
                    ogimage{fullpath}
                    overviewTitle
                    overviewIntro
                    seodescription
                    seotitle
                    listTitle1
                    listTitle2
                    listTitle3
                    listTitle4
                    listTitle5
                    labelText1
                    labelText2
                    labelText3
                    labelText4
                    labelText5
                    allPartnersButtonText
                    partnerWebsiteLinkText
                    list1 {
                        ... PartnerFragment
                    }
                    list2 {
                        ... PartnerFragment
                    }
                    list3 {
                        ... PartnerFragment
                    }
                    list4 {
                        ... PartnerFragment
                    }
                    list5 {
                        ... PartnerFragment
                    }
                }
            }
        }
    }
    }
`;
