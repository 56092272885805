import React, { useState, Fragment } from "react";
import { LocalizedLink as Link } from "@components/util/LanguageProvider";
import { useDispatch, useSelector } from "react-redux";
import css from "./Dropdown.module.scss";
import { setIsMobileNavigationOpen as setIsMobileNavigationOpenAction, setIsStickyNavigationOpen as setIsStickyNavigationOpenAction } from "@redux/actions";
import { useUrlBuilder } from "@util/url-builder";

const Dropdown = (props) => {
    const [IsDropdownOpen, setIsDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const isStickyNavigationOpen = useSelector((state) => state.isStickyNavigationOpen);

    const toggleList = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const closeList = () => {
        setIsDropdownOpen(false);
    };

    const openList = () => {
        if (!IsDropdownOpen) {
            setIsDropdownOpen(true);
        }
    };

    const DropdownLabel = () => {
        return (
            <Fragment>
                {props.headerTitle}
                <img
                    className={`${css.img}`}
                    alt="arrowLogo"
                    src={
                        isStickyNavigationOpen
                            ? typeof document !== undefined &&
                            (IsDropdownOpen ||
                                document.URL.substring(document.URL.lastIndexOf('/') + 1) === ("diensten")
                                ? "/assets/icons/down-arrow-blue.svg"
                                : "/assets/icons/down-arrow-black.svg")
                            : "/assets/icons/down-arrow.svg"
                    }
                />
            </Fragment>
        );
    };

    const { buildLocalizedStaticUrl } = useUrlBuilder();

    return (
        <div onMouseLeave={closeList}>
            {props.headerUrl && props.headerUrl !== ''
                ? (<Link onMouseOver={openList} to={props.headerUrl}>
                    {props.headerTitle}
                    <img
                        className={`${css.img}`}
                        alt="arrowLogo"
                        src={
                            isStickyNavigationOpen
                                ? typeof document !== undefined &&
                                (IsDropdownOpen ||
                                    document.URL.substring(document.URL.lastIndexOf('/') + 1) === ("diensten")
                                    ? "/assets/icons/down-arrow-blue.svg"
                                    : "/assets/icons/down-arrow-black.svg")
                                : "/assets/icons/down-arrow.svg"
                        }
                    /> </Link>)
                : (<span className={'group-anchor'} onMouseOver={openList} onClick={toggleList}>
                    {props.headerTitle}
                    <img
                        className={`${css.img}`}
                        alt="arrowLogo"
                        src={
                            isStickyNavigationOpen
                                ? typeof document !== undefined &&
                                (IsDropdownOpen ||
                                    document.URL.substring(document.URL.lastIndexOf('/') + 1) === ("diensten")
                                    ? "/assets/icons/down-arrow-blue.svg"
                                    : "/assets/icons/down-arrow-black.svg")
                                : "/assets/icons/down-arrow.svg"
                        }
                    />
                </span>)
            }
            {IsDropdownOpen && (
                <div className={`${css.elements}`}>
                    <ul>
                        {props.dropdownInfo.map((item) => (
                            <li key={item.id} className={`${css.element}`}>
                                <Link
                                    to={buildLocalizedStaticUrl(item.Link)}
                                    onClick={() => {
                                        closeList();
                                        dispatch(setIsMobileNavigationOpenAction(false));
                                    }}
                                >
                                    <span>{item.Name}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
