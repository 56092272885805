import React from "react";
import { LocalizedLink as Link } from "@components/util/LanguageProvider";
import urlBuilder from '../../../util/url-builder';
import Heading from '@components/common/Heading/Heading';
import css from './VacancyList.module.scss';
import { __ } from "@i18n/util";
import { useUrlBuilder } from "@util/url-builder";

const VacancyList = ({ vacancies, anchor }) => {
    const { buildDynamicUrl } = useUrlBuilder();

    return (
        <div id={anchor}>
            <Heading isSemantic={true} variant="h2" className={css.title}>{ __("Our vacancies") }</Heading>

            {vacancies ?
                <ul className={css.list}>
                    {vacancies?.map(({ id, functiontitle }) => (
                        <li key={id} className={css.listItem}>
                            <Link
                                className={css.link}
                                to={buildDynamicUrl(id)}
                            >
                                <span>{functiontitle}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
                : <div className={css.block}>
                    <div className="alert alert-warning">
                        <strong>{__("There are currently no open vacancies.")}</strong>
                        <br />{__("Check back on this page later to make sure you don't miss any.")}
                    </div>
                </div>
            }
        </div>
    );
};

export default VacancyList;