const syspath = require("path");

const _kebabCase = (string) => {
    if (string === null || string === undefined) {
        return '';
    }
    return string
        .match(/[A-Z]{2,}(?=[A-Z][a-z0-9]*|\b)|[A-Z]?[a-z0-9]*|[A-Z]|[0-9]+/g)
        .filter(Boolean)
        .map((x) => x.toLowerCase())
        .join("-");
};

module.exports.kebabCase = _kebabCase;

const _kebabCaseSlug = (string) => {
    // Remove leading / if present
    if (string.startsWith('/')) {
        string = string.substring(1);
    }
    if (string.includes('/')) {
        return string;
    }
    return string
        .match(/\/|[A-Z]{2,}(?=[A-Z][a-z0-9]*|\b)|[A-Z]?[a-z0-9]*|[A-Z]|[0-9]+/g)
        .filter(Boolean)
        .map((x) => x.toLowerCase())
        .join("-")
        .replace(/-?\/-?/g, '/') // Then handle the original patterns
        .replace(/\/\/+/g, '/') // Explicitly target multiple slashes first
};

module.exports.kebabCaseSlug = _kebabCaseSlug;

const _kebabCase2 = (string) => {
    if (!string || string === '') return '';

    // Remove leading / if present
    if (string.startsWith('/')) {
        string = string.substring(1);
    }

    return string
        .match(/\/|[A-Z]{2,}(?=[A-Z][a-z0-9]*|\b)|[A-Z]?[a-z0-9]*|[A-Z]|[0-9]+/g)
        .filter(Boolean)
        .map((x) => x.toLowerCase())
        .join("-")
        .replace(/-?\/-?/g, '/'); // Replace -/-, -/, or /- with /
};

module.exports.kebabCase2 = _kebabCase2;

const mappedLocales = {
    nl_BE: '',
    en: 'en/',
}

const _routes = [
    // TODO: add englishVersion: 'englishVersion',
    {
        id: '404',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: '404.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: '404'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: '404'
            }
        }
    },
    {
        id: 'index',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'index.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: '/'
            },
            en: {
                directory: 'en', /* '' directory -> root */
                slug: ''
            }
        }
    },
    {
        id: 'vacancyletterpage',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'vacancy-letter.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'vacancyletterpage'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'vacancyletterpage'
            }
        }
    },
    {
        id: 'vacancycontactpage',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'vacancy-contact.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'vacancycontactpage'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'vacancycontactpage'
            }
        }
    },
    {
        id: 'webinarspage',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'webinars.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'webinarspage'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'webinarspage'
            }
        }
    },
    {
        id: 'blogpage',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'blog.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'blog'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'blog'
            }
        }
    },
    {
        id: 'teampage',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'team.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'team'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'team'
            }
        }
    },
    {
        id: 'jobspage',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'jobs.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'jobs'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'jobs'
            }
        }
    },
    {
        id: 'studioemma',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'studio-emma.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'studio-emma'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'studio-emma'
            }
        }
    },
    {
        id: 'newsletter',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'newsletter.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'newsletter'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'newsletter'
            }
        }
    },
    {
        id: 'proces',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'proces.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'proces'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'proces'
            }
        }
    },
    {
        id: 'contact',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'contact.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'contact'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'contact'
            }
        }
    },
    {
        id: 'partners',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'partners.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'partners'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'partners'
            }
        }
    },
    {
        id: 'diensten',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'diensten.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'diensten'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'services'
            }
        }
    },
    {
        id: 'portfolio',
        isGatsbyPage: true,
        query: '',
        identifier: '',
        slug: '', // 'pageTitle',
        template: 'portfolio.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '', /* '' directory -> root */
                slug: 'portfolio'
            },
            en: {
                directory: 'en/', /* '' directory -> root */
                slug: 'portfolio'
            }
        }
    },
    {
        id: 'pages',
        query: 'getPageListing',
        identifier: 'id',
        slug: 'seoPageTitle', // 'pageTitle',
        template: 'generic-page.js',
        englishVersion: 'englishVersion',
        locale: {
            nl_BE: {
                directory: '' /* '' directory -> root */
            },
            en: {
                directory: '' /* '' directory -> root */
            }
        }
    },
    {
        id: 'posts',
        query: 'getNewsPostListing',
        identifier: 'id',
        slug: 'seotitle', // 'Title',
        template: 'news-post.js',
        englishVersion: 'englishVersion',
        locale: {
            nl_BE: {
                directory: '',
                slug: "testingpostsnl"
            },
            en: {
                directory: '',
                slug: "testingpostsen"
            }
        }
    },
    {
        id: 'projects',
        query: 'getProjectListing',
        identifier: 'id',
        slug: 'seotitle', // 'projecttitle',
        template: 'project-post.js',
        englishVersion: 'englishVersion',
        locale: {
            nl_BE: {
                directory: 'portfolio'
            },
            en: {
                directory: 'portfolio'
            }
        }
    },
    {
        id: 'projectsNew',
        query: 'getProjectnewListing',
        identifier: 'id',
        slug: 'seotitle', // 'projecttitle',
        template: 'project-post-new.js',
        englishVersion: 'englishVersion',
        locale: {
            nl_BE: {
                directory: 'portfolio'
            },
            en: {
                directory: 'portfolio'
            }
        }
    },
    {
        id: 'testimonials',
        query: 'getTestimonialListing',
        identifier: 'id',
        slug: 'seotitle', // 'title',
        template: 'testimonial-post.js',
        englishVersion: 'englishVersion',
        locale: {
            nl_BE: {
                directory: 'testimonial'
            },
            en: {
                directory: 'testimonial'
            }
        }
    },
    {
        id: 'teammembers',
        query: 'getTeamMemberListing',
        identifier: 'id',
        slug: 'seotitle', // 'name',
        template: 'team-post.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: 'team'
            },
            en: {
                directory: 'team'
            }
        }
    },
    {
        id: 'webinars',
        query: 'getWebinarListing',
        identifier: 'id',
        slug: 'seotitle', // 'name',
        template: 'webinar-post.js',
        englishVersion: 'englishVersion',
        locale: {
            nl_BE: {
                directory: 'webinars'
            },
            en: {
                directory: 'webinars'
            }
        }
    },
    {
        id: 'services',
        query: 'getFeaturedServiceListing',
        identifier: 'id',
        slug: 'title', // 'functiontitle',
        template: 'featured-service.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '' /* '' directory -> root */
            },
            en: {
                directory: '' /* '' directory -> root */
            }
        }
    },
    {
        id: 'landingsPage',
        query: 'getLandingpageListing',
        identifier: 'id',
        slug: 'seoPageTitle', // 'functiontitle',
        template: 'landingspage.js',
        englishVersion: 'englishVersion',
        locale: {
            nl_BE: {
                directory: '' /* '' directory -> root */
            },
            en: {
                directory: '' /* '' directory -> root */
            }
        }
    },
    {
        id: 'partnerDetail',
        query: 'getTechnologyLinkListing',
        identifier: 'id',
        slug: 'seotitle', // 'functiontitle',
        template: 'partner-detail.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: '' /* '' directory -> root */
            },
            en: {
                directory: '' /* '' directory -> root */
            }
        }

    },
    {
        id: 'vacancies',
        query: 'getVacancyListing',
        identifier: 'id',
        slug: 'seotitle', // 'functiontitle',
        template: 'vacancy-post.js',
        englishVersion: '',
        locale: {
            nl_BE: {
                directory: 'jobs'
            },
            en: {
                directory: 'jobs'
            }
        }
    },

];

module.exports._routes = _routes;

module.exports.query = `
    query {
        pim {
            ${_routes.filter((route) => { return !route.isGatsbyPage }).map(({ id, query, identifier, slug }) => `${id}: ${query}(defaultLanguage: "auto") { edges { node { ${identifier} ${slug} } } } `)}
        }
    }
`;

module.exports.queryEnglish = `
    query {
        pim {
            ${_routes.filter((route) => { return !route.isGatsbyPage }).map(({ id, query, identifier, slug, englishVersion }) => `${id}: ${query}(defaultLanguage: "en") { edges { node { ${identifier} ${slug} ${englishVersion} } } } `)}
        }
    }
`;

module.exports.queryLanguageSwitch =
    `
query {
     pim {
        getHeaderListing(defaultLanguage: "auto") {
            edges {
                node {
                    LanguageSwitch
                }
            }
        }
    }
}
`;

module.exports.createPageGenerator = (
    actions
) => (
    pagesQuery,
    config = {},
    lang
) => {
        const generatedRoutes = [];

        for (const index in _routes) {
            const route = _routes[index];
            const { directory } = route.locale[lang];
            const locale = mappedLocales[lang];
            const group = (directory && directory !== '') ? `${route.locale[lang].directory}/` : '';
            // FIXED: ${route.local[lang].directory/} had a / before it which caused double slashes

            if (!route.isGatsbyPage) {
                // console.log(route, "<<<-------route'");
                if (route.query === 'getPageListing') {
                    // console.log(pagesQuery.data, 'PPPPPPPPP');
                }
                const pages = pagesQuery.data.pim[route.id].edges;

                for (const index in pages) {
                    const page = pages[index].node;
                    const pathIdentifier = `-${page[route.identifier]}`;
                    const pathSlug = `${page[route.slug]}`;
                    const pathUnique = `${locale}${group}${_kebabCase(`${pathSlug}${pathIdentifier}`)}`;
                    const pathShorthandPre = `${locale}${group}${_kebabCase(`${pathSlug}`)}`;
                    const pathShorthand = _kebabCaseSlug(`${pathShorthandPre}`);

                    const doesPathExists = generatedRoutes.includes(pathShorthand);
                    const path = (doesPathExists) ? pathUnique : pathShorthand;

                    // console.log('Before if', lang, '| [logger] generating pim page', { lang: lang, path: path, check: page[route.englishVersion], id: route.englishVersion, condition: (lang === 'en' && page[route.englishVersion] !== null && page[route.englishVersion] !== false), page: page });

                    if (
                        page[route.slug] === null ||
                        page[route.slug] === undefined ||
                        path === '' ||
                        (lang === 'en' && (page[route.englishVersion] === null || page[route.englishVersion] === false))
                    )
                        continue;

                    // FIXED => TODO: CHECK ALS PATH NU ZONDER DUBBELE // IS
                    let normalizedPath = _kebabCaseSlug(path);

                    console.log(lang, '| [logger] generating pim page', path);
                    actions.createPage({
                        path: normalizedPath,
                        component: syspath.resolve(`./src/templates/${route.template}`),
                        context: {
                            id: parseInt(page[route.identifier]),
                            localeLang: lang,
                            config: config
                        }
                    });

                    generatedRoutes.push(pathShorthand);
                    // TODO: Check if all generated routes are single / or double //
                }

            } else {
                const toHash = (text) => {
                    var hash = 0,
                        i, chr;
                    if (text.length === 0) return hash;
                    for (i = 0; i < text.length; i++) {
                        chr = text.charCodeAt(i);
                        hash = ((hash << 5) - hash) + chr;
                        hash |= 0; // Convert to 32bit integer
                    }
                    return hash;
                }

                // console.log('static id', route.id, 'hash', toHash(route.id));

                const path = `${locale}${route.locale[lang].slug}`;
                console.log(lang, '| [logger] generating static page', path);
                const gatsbyPage = {
                    path: path,
                    component: syspath.resolve(`./src/templates/${route.template}`),
                    context: {
                        // TODO: add unique identifier
                        id: toHash(route.id),
                        localeLang:
                            lang,
                        config: config
                    }
                }
                if (route.id === '404') {
                    gatsbyPage.matchPath = locale === "nl_BE" ? `/*` : `/${lang}/*`;
                }
                actions.createPage(gatsbyPage);


                // generatedRoutes.push(pathShorthand);
            }

        }
    };