import React, { Fragment, useEffect, useState } from "react";
import { useLocaleContext } from "@components/util/LanguageProvider";

export const EmptyRender = () => {
    if (process.env.NODE_ENV === 'development') {
    }
    return <Fragment><h1 style={{ color: "red" }}>EMPTY RENDER RAN</h1></Fragment>;
};

export const useLocalizedData = (data, key, options) => {
    const { locale } = useLocaleContext();
    const localizedData = data[`${key}_${locale}`];

    if (options?.emptyCheck && (localizedData['doNotRender'] === undefined || localizedData['doNotRender'] === null)) {
        localizedData['doNotRender'] = doNotRenderIfEmpty(localizedData, options?.emptyCheck);
    }

    return localizedData;
}

export const doNotRenderIfEmpty = (data, key) => {
    if (!data) {
        return true;
    }

    if (!data[key]) {
        return true;
    }

    return false;
}

export const useEnableFlag = (query) => {
    const { locale } = useLocaleContext();

    if (locale !== 'en') {
        return query;
    }

    const filterEnglishVersion = (data) => {
        if (Array.isArray(data)) {
            return data.filter(item => filterEnglishVersion(item));
        } else if (typeof data === 'object' && data !== null) {
            for (const key in data) {
                if (Array.isArray(data[key])) {
                    data[key] = filterEnglishVersion(data[key]);
                } else if (key === 'englishVersion' && (data[key] === false || data[key] === null)) {
                    return false;
                }
            }
        }
        return true;
    };

    if (query && query.edges && locale === 'en') {
        const newEdges = query.edges.map(edge => {
            const node = { ...edge.node }; // Create a shallow copy of the node
            if (!filterEnglishVersion(node)) {
                return null;
            }
            return { ...edge, node }; // Return a new edge object with the copied node
        }).filter(edge => edge !== null); // Filter out null edges

        const updatedQuery = { ...query, edges: newEdges }; // Create a new query object
        return updatedQuery;
    }

    return query;
};

