import { Location } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import css from "./ContactButtons.module.scss";

import { EmptyRender, doNotRenderIfEmpty, useLocalizedData } from '@components/util/LanguageHooks';

import { getFirstItemFromQuery } from "@util/mapper";

const ContactButtonsQuery = () => {
    const { pim } = useStaticQuery(graphql`
        fragment ContactButtonsFragment on PIM_HeaderConnection {
                    edges {
                        node {
                            CallPhoneNumber
                            WhatsappPhoneNumber
                            Email
                        }
                    }
            }
        query {
            pim {
                getHeaderListing_nl_BE: getHeaderListing(defaultLanguage: "nl_BE") {
                    ...ContactButtonsFragment
                }
                getHeaderListing_en: getHeaderListing(defaultLanguage: "en") {
                    ...ContactButtonsFragment
                }
            }
        }
    `);

    const contactInfoQuery = useLocalizedData(pim, 'getHeaderListing');

    const contactInfo = getFirstItemFromQuery(contactInfoQuery);


    if (contactInfoQuery.doNotRender) { return <EmptyRender />; }

    return (
        <div className={css.container}>
            <a href={"mailto:" + contactInfo.Email}>
                <div className={css.button}>
                    <img
                        loading="lazy"
                        alt="envelope"
                        title="envelope"
                        width="15px"
                        height="15px"
                        src="/assets/icons/envelope.svg"
                        className={css.img}
                    />
                </div>
            </a>
            <a href={"tel://" + contactInfo.CallPhoneNumber}>
                <div className={css.button}>
                    <img
                        loading="lazy"
                        alt="phone"
                        title="phone"
                        width="15px"
                        height="15px"
                        src="/assets/icons/phone.svg"
                        className={css.img}
                    />
                </div>
            </a>
            {/* <Location>
                {({ location }) => {
                    if (
                        contactInfo.WhatsappPhoneNumber.length !== 0 &&
                        (location.pathname.includes("/jobs") ||
                        location.pathname.includes("/vacancy-contact"))
                    ) {
                        return (
                            <a
                                href={
                                    "https://wa.me/" +
                                    contactInfo.WhatsappPhoneNumber
                                } target="_blank" rel="noopener noreferrer"
                            >
                                <div className={css.button}>
                                    <img
                                        loading="lazy"
                                        alt="whatsapp"
                                        title="whatsapp"
                                        width="15px"
                                        height="15px"
                                        src="/assets/icons/whatsapp.svg"
                                        className={css.img}
                                    />
                                </div>
                            </a>
                        );
                    }
                }}
            </Location> */}
        </div>
    );
};

const Page = () => {
    return <ContactButtonsQuery />;
};

export default Page;