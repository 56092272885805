import React, { Fragment } from 'react'
import { graphql, useStaticQuery} from "gatsby";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import classNames from "classnames";
import urlBuilder from "@util/url-builder";
import Heading from '@components/common/Heading/Heading';
import LinkButton from "@components/common/LinkButton/LinkButton";

import css from './ServicesGrid.module.scss';
import cssHeading from '@components/common/Heading/Heading.module.scss';
import { useLocaleContext } from '@components/util/LanguageProvider';
import { EmptyRender, useLocalizedData, doNotRenderIfEmpty } from '@components/util/LanguageHooks';
import { useUrlBuilder } from "@util/url-builder";

function ServicesGrid() {
    const { pim } = useStaticQuery(graphql`
        fragment HomeConfigFragment on PIM_object_HomeConfig {
            Onsprocespicture {
                id
                fullpath
            }
            onsprocessectiontitle
            onsprocessectiontext
            onsprocesButtontitle
            onsprocesURL
            onsproces {
                ... on PIM_object_Page {
                    id
                    pageIntroTitle
                    pageIntroSubtitle
                }
            }
        }
        query {
            pim {
                getHomeConfig_nl_BE: getHomeConfig(id: 157, defaultLanguage: "nl_BE") {
                    ...HomeConfigFragment
                }
                getHomeConfig_en: getHomeConfig(id: 157, defaultLanguage: "en") {
                    ...HomeConfigFragment
                }
            }
        }
    `);

    const { onsprocessectiontitle, onsprocessectiontext, onsprocesButtontitle, onsprocesURL, doNotRender} = useLocalizedData(pim, 'getHomeConfig', { emptyCheck: 'onsprocessectiontitle' });

    const { buildLocalizedStaticUrl } = useUrlBuilder();

    if (doNotRender) { return <EmptyRender />; }

    return (
        <div className={css.servicesGrid}>
            <div className={css.servicesGridSection}>
                <div className={css.servicesGridSectionContent}>
                    <Heading
                        variant="h2"
                        isSemantic={true}>
                            {onsprocessectiontitle}
                    </Heading>
                    <p>{onsprocessectiontext}</p>
                </div>
            </div>

            <div className={css.servicesGridItems}>
                {
                    pim.getHomeConfig?.onsproces?.map(({ id, pageIntroTitle, pageIntroSubtitle }) =>
                        (
                            <div className={css.servicesGridItem} key={id}>
                                <div className={classNames(css.servicesGridItemTitle, cssHeading.h2)}
                                    data-title={pageIntroTitle}>
                                        {pageIntroTitle}
                                </div>

                                <p className={css.servicesGridItemSubtitle}>{pageIntroSubtitle}</p>
                            </div>
                        )
                    )
                }
            </div>
            <div className={css.servicesGridButton}>
                <LinkButton
                    link={buildLocalizedStaticUrl(onsprocesURL)}
                    variant="blue"
                    text={onsprocesButtontitle}
                    uppercase={false}
                />
            </div>
        </div>
    )
}

export default ServicesGrid