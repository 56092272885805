/* eslint eqeqeq: 0 */

import React from "react";
import { graphql } from "gatsby";
import { __ } from "@i18n/util";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import css from "@styles-pages/landingspage.module.scss";
import classNames from "classnames";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import LinkButton from "@components/common/LinkButton/LinkButton";
import ImageSlider from "@components/vacancies/ImageSlider/ImageSlider";
import { SeoMetaTags } from "../components/util/SOE/SOE";
import SoftwareCard from "../components/landingspage/SoftwareCard";
import CheckList from "../components/landingspage/CheckList";
import UspsList from "../components/landingspage/UspsList";
import RichContent from "../components/common/RichContent/RichContent";
import { useLocaleContext } from '@components/util/LanguageProvider';


/* eslint-disable no-undef */
export const metadata = {
    query: 'getLandingpage',
    title: 'seoPageTitle',
    description: 'seoPageMetaDescription',
    image
};

const Landingspage = (props) => {
    const { __: t } = useLocaleContext();

    const { data } = props;

    const {
        seoPageTitle,
        seoPageMetaDescription,
        checklistTitle,
        aboutCTAtitle,
        aboutSE,
        aboutSE1,
        aboutSE2,
        blocksIntroduction,
        blocksTitle,
        contactCTAtitle,
        contactContent,
        ctaTxt,
        ctaUrl,
        logoSectionTitle,
        pageContent,
        pageContent2,
        pageIntroHtml,
        pageReference,
        superTitle,
        sfeerfotoSE,
        logoSlider,
        teammembers,
        ContactFormPage,
        titleH1 } = data.pim.getLandingpage;

    const { checks } = data.pim.getLandingpage;

    let checkObject = checks;

    const { blocks } = data.pim.getLandingpage;

    let blocksObject = blocks;

    const { usps } = data.pim.getLandingpage;

    let uspsObject = usps;

    let logoSliderObject = logoSlider.map((l) => {
        return {
            id: l.logo.id,
            text: l.customer,
            fullpath: l.logo['fullpath'],
        }
    })

    let teammembersObject = teammembers;

    const getBlockLogosFromItem = (item, amount) => {
        const result = [item.blockImg];
        for (let i = 1; i <= amount; i++) {
            if (i === 1) result.push(item.blockLogo)
            else result.push(item[`blockLogo${i}`]);
        }
        return result;
    }


    return (
        <>
            <SeoMetaTags pim={data.pim} metadata={metadata} />
            <ContentWrapper isWide={true} className={css.mainContentWrapper}>
                <Header size="oneLiner">

                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >

                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >

                            {/* NOTE: NON WYSIWYG feature */}

                            <div className={classNames(css.superTitle)}>
                                {superTitle}
                            </div>

                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                {titleH1}
                            </Heading>
                            <div className={classNames(css.pageIntro)}>
                                <RichContent>{pageIntroHtml}</RichContent>

                            </div>

                            {ctaTxt && <LinkButton
                                variant="orangeRevert"
                                text={ctaTxt}
                                link={ctaUrl ?? 'not-found'}
                                uppercase={false}
                                marginTop
                            />}

                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                <ContentWrapper paddedTop
                    isWide={false}
                    className={css.contentWrapper}
                >
                    <div className={classNames(css.checkListSection)}>
                        <h2 className={classNames(css.title)}>{checklistTitle}</h2>

                        <div className={classNames(css.gridContainer)}>
                            <RichContent className={classNames(css.left)} >
                                {pageContent}
                            </RichContent>

                            <div className={classNames(css.right)}>
                                <CheckList data={checkObject} />
                            </div>

                        </div>
                    </div>

                </ContentWrapper>
                {/* NOTE: WIE ZIJN ONZE KLANTEN? */}

                <ContentWrapper paddedTop paddedBottom maxWidth="maxWidthMax">
                    <div className={classNames(css.customers)}>
                        <h2 className={classNames(css.title)}>{logoSectionTitle}</h2>
                        <ImageSlider imageObjects={logoSliderObject} imageTitles width="fixed">
                        </ImageSlider>
                    </div>
                </ContentWrapper>

                {/* NOTE: PIMCORE BLOCKS --- E-COMMERCE EN WEBSHOP SOFTWARE */}
                <ContentWrapper bgColor="bgColorLightBlue" maxWidth="maxWidthMax" isDesktopWide id="solutions">
                    <ContentWrapper paddedBottom paddedTop paddedNoSides
                    >
                        <div className={classNames(css.softwareSection)}>
                            <h2 className={classNames(css.title)} >{blocksTitle}</h2>
                            <RichContent className={classNames(css.introduction)} >
                                {blocksIntroduction}
                            </RichContent>

                            <div className={classNames(css.gridContainer)}>
                                {blocksObject.map((item) => {
                                    return (
                                        <SoftwareCard
                                            text={item.blockContent}
                                            footer={item.blockCTA}
                                            images={getBlockLogosFromItem(item, 9)}
                                        />
                                    )
                                })}

                            </div>

                        </div>

                    </ContentWrapper>
                </ContentWrapper>

                {/* NOTE: TEAM FOTO */}

                <ContentWrapper paddedBottom paddedTop>
                    <div className={classNames(css.hugePicture)}>
                        <img
                            alt="Studio Emma team"
                            loading="lazy"
                            src={generateThumbnailUrl(
                                sfeerfotoSE,
                                "header_background_thumbnail"
                            )}
                        />
                    </div>
                </ContentWrapper>

                <ContentWrapper id="approach"
                    isWide={false}>
                    <div className={classNames(css.partnerSection)}>
                        <h2 className={classNames(css.title)}>{aboutCTAtitle}</h2>
                        <div className={classNames(css.gridContainer)}>
                            <RichContent className={classNames(css.subContainer)}>
                                {aboutSE}
                            </RichContent>
                            <RichContent className={classNames(css.subContainer)}>
                                {aboutSE1}
                            </RichContent>
                            <RichContent className={classNames(css.subContainer)}>
                                {aboutSE2}
                            </RichContent>
                        </div>
                    </div>
                </ContentWrapper>

                {/* NOTE: USPS -- 100+ SUCCESVOLLE PROJECTEN  */}
                <ContentWrapper paddedBottom paddedTop>
                    <div className={classNames(css.usps)}>
                        <UspsList data={uspsObject}></UspsList>
                    </div>
                </ContentWrapper>

                {/* NOTE: CONTACT CTA -- JOUW PLANNEN */}
                <ContentWrapper bgColor="bgColorLightBlue" maxWidth="maxWidthMax" isDesktopWide id="contact">
                    <ContentWrapper paddedTop paddedBottom paddedNoSides>
                        <div className={classNames(css.plannen)}>
                            <div className={classNames(css.gridContainer)}>
                                <div className={classNames(css.left)}>
                                    <h2 className={classNames(css.title)} dangerouslySetInnerHTML={{ __html: contactCTAtitle }} />
                                    <RichContent className={classNames(css.text)} >
                                        {contactContent}
                                    </RichContent>
                                    <div className={classNames(css.contactList)}>

                                        {teammembersObject.map((item) => {
                                            return (
                                                <div className={classNames(css.contact)}>

                                                    <img className={classNames(css.contactPicture)}

                                                        loading="lazy"
                                                        alt={`${t("Picture of")} ${item.name}`}
                                                        src={generateThumbnailUrl(
                                                            item.portrait3,
                                                            "portrait_thumbnail"
                                                        )}
                                                    />
                                                    <div className={classNames(css.contactName)}>
                                                        {item.name}
                                                    </div>
                                                    <div className={classNames(css.contactFunction)} >
                                                        {item.expertiseShort}
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                                <div className={classNames(css.right)} dangerouslySetInnerHTML={{ __html: ContactFormPage }}>
                                </div>
                            </div>
                        </div>
                    </ContentWrapper>
                </ContentWrapper>

            </ContentWrapper></>

    );
};

export default Landingspage;

export const query = graphql`
query($id: Int!, $localeLang: String!) {
    pim {
            getLandingpage(id: $id, defaultLanguage: $localeLang) {
                seoPageTitle
                seoPageMetaDescription
                checklistTitle
                aboutCTAtitle
                aboutSE
                aboutSE1
                aboutSE2
                blocksIntroduction
                blocksTitle
                contactCTAtitle
                contactContent
                ContactFormPage
                ctaTxt
                ctaUrl
                logoSectionTitle
                pageContent
                pageContent2
                pageIntroHtml
                pageReference
                superTitle
                titleH1
                checks {
                    ... on PIM_object_Check {
                    id
                    checkContent
                    checkContent2
                    chekImage{
                        id
                        fullpath
                    }
                    checkTitle
                    checkTitle2
                    classname
                    }
                }
                blocks {
                    ... on PIM_object_Block {
                    id
                    blockCTA
                    blockContent
                    blockImg {
                        id
                        fullpath
                    }
                    blockLogo {
                        id
                        fullpath
                    }
                    blockLogo2 {
                        id
                        fullpath
                    }
                    blockLogo3 {
                        id
                        fullpath
                    }
                    blockLogo4 {
                        id
                        fullpath
                    }
                    blockLogo5 {
                        id
                        fullpath
                    }
                    blockLogo6 {
                        id
                        fullpath
                    }
                    blockLogo7 {
                        id
                        fullpath
                    }
                    blockLogo8 {
                        id
                        fullpath
                    }
                    blockLogo9 {
                        id
                        fullpath
                    }
                    }
                }
                sfeerfotoSE {
                    id
                    fullpath
                }
                usps {
                    ... on PIM_object_USP {
                    id
                    USPdescription
                    USPimage {
                        id
                        fullpath
                    }
                    USPnumber
                    }
                }
                logoSlider {
                    ... on PIM_object_CustomerLink {
                    id
                    logo {
                        id
                        fullpath
                    }
                    customer
                    }
                }
                teammembers {
                    ... on PIM_object_TeamMember {
                    id
                    name
                    portrait3{
                        id
                        fullpath
                    }
                    expertise
                    expertiseShort
                    }
                }
            }
        }
    }
`;
