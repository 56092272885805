import React from "react";
import classNames from "classnames";
import css from "./jobCard.module.scss";
import { LocalizedLink as Link } from "@components/util/LanguageProvider";
import CardContainer from "../CardContainer/CardContainer";
import { useUrlBuilder } from "@util/url-builder";

const JobCard = ({ title, image, imageHover, text, className, variant, freeLink }) => {

    const { buildLocalizedStaticUrl } = useUrlBuilder();

    return (
        <div className={classNames(css.jobCard, className, css[variant])}>
            {freeLink 
                ? <Link to={buildLocalizedStaticUrl(freeLink)}><CardContainer title={title} image={image} imageHover={imageHover} text={text}/></Link>                   
                : <CardContainer title={title} image={image} imageHover={imageHover} text={text}/>}
        </div>
    );
};

export default JobCard;
