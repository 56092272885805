import Card from "@components/common/Card/Card";
import CardGrid from "@components/common/CardGrid/CardGrid";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import CustomerGrid from "@components/references/CustomerGrid/CustomerGrid";
import LinkButton from "@components/common/LinkButton/LinkButton";
import LinkButtonRow from "@components/common/LinkButtonRow/LinkButtonRow";

import css from "@styles-pages/portfolio.module.scss";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import urlBuilder from "@util/url-builder";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { checkButton } from '@util/check-button';
import { SeoMetaTags } from "../components/util/SOE/SOE";
import Fade from '@components/util/Fade';
import { useEnableFlag } from '@components/util/LanguageHooks';
import { useUrlBuilder } from "@util/url-builder";


/* eslint-disable no-undef */
export const metadata = {
    query: 'getReferencesConfigListing',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const ProjectSection = ({ title, subtitle, projects }) => {
    const { buildDynamicUrl } = useUrlBuilder();

    return (
        <ContentWrapper paddedBottom>
            <Heading isSemantic={true} variant="h2">
                {title} testing
            </Heading>
            <p className={css.subtitle}>{subtitle}</p>
            <CardGrid filter={3}>
                {projects.map((project) => (
                    <Card
                        key={project.id}
                        title={project.projecttitle}
                        subtitle={project.projectsubtitle}
                        url={buildDynamicUrl(project.id)}
                        image={generateThumbnailUrl(
                            project.previewimage,
                            "case_preview"
                        )}
                    />
                ))}
            </CardGrid>
        </ContentWrapper>
    );
};

const useTestimonialSection = ({ title, subtitle, testimonials }) => {
    const { buildDynamicUrl } = useUrlBuilder();

    return (
        <ContentWrapper paddedBottom>
            <Heading isSemantic={true} variant="h2">
                {title}
            </Heading>

            <p className={css.subtitle}>{subtitle}</p>

            <CardGrid filter={3}>
                {testimonials.map((testimonial) => (
                    <Card
                        key={testimonial.id}
                        title={testimonial.title}
                        subtitle={testimonial.subtitle}
                        url={buildDynamicUrl(testimonial.id)}
                        image={generateThumbnailUrl(
                            testimonial.previewimage,
                            "card_preview_thumbnail"
                        )}
                    />
                ))}
            </CardGrid>
        </ContentWrapper>
    );
};

const TestimonialSection = (props) => {
    return useTestimonialSection(props);
};

const CustomersSection = ({ title, customers }) => (
    <ContentWrapper>
        <CustomerGrid title={title} customers={customers} />
    </ContentWrapper>
);

const ReferenceQuery = (props) => {

    const pim = props.data.pim;

    const filteredReferenceConfigListing = useEnableFlag(pim.getReferencesConfigListing);
    console.log("Portfolio page: ",filteredReferenceConfigListing)

    const dataConfig = filteredReferenceConfigListing.edges[0].node;

    const { stucturedData } = dataConfig;

    const isBtn1 = checkButton(dataConfig.button1txt, dataConfig.button1URL);
    const isBtn2 = checkButton(dataConfig.button2txt, dataConfig.button2URL);

    const { buildLocalizedStaticUrl } = useUrlBuilder();

    return (
        <ContentWrapper isWide={true}>
            <SeoMetaTags pim={pim} metadata={metadata} structureddata={stucturedData} />
            <Header image={true} size="oneLiner">
                <ContentWrapper
                    isWide={false}
                    className={css.contentWrapper}
                >
                    <ContentWrapper
                        isWide={true}
                        maxWidth="maxWidthSmall"
                    >
                        <Heading isSemantic={true} variant="h1" color="white">
                            {dataConfig.pagetitle}
                        </Heading>
                        <HighlightedText color="white">
                            {dataConfig.pagesubtitle}
                        </HighlightedText>

                        {(isBtn1 || isBtn2) && (
                            <LinkButtonRow>
                                {isBtn1 && (
                                    <LinkButton
                                        link={buildLocalizedStaticUrl(dataConfig.button1URL)}
                                        variant="orangeRevert"
                                        text={dataConfig.button1txt}
                                    />
                                )}

                                {isBtn2 && (
                                    <LinkButton
                                        link={buildLocalizedStaticUrl(dataConfig.button2URL)}
                                        variant="orangeRevert"
                                        text={dataConfig.button2txt}
                                    />
                                )}
                            </LinkButtonRow>
                        )}
                    </ContentWrapper>
                </ContentWrapper>
            </Header>
            <ContentWrapper isWide={true} paddedTop={true} paddedBottom={true}>
                <Fade duration={2000}>
                    <TestimonialSection
                        title={dataConfig.testimonialstitle}
                        subtitle={dataConfig.testimonialssubtitle}
                        testimonials={dataConfig.testimonials}
                    /></Fade>
                <Fade duration={2000}>
                    <ProjectSection
                        title={dataConfig.projectstitle}
                        subtitle={dataConfig.projectssubtitle}
                        projects={dataConfig.projects}
                    /></Fade>
                <Fade duration={2000}>
                    <CustomersSection
                        title={dataConfig.customerstitle}
                        customers={dataConfig.customers}
                    /></Fade>
            </ContentWrapper>
        </ContentWrapper>
    );
};

export default ReferenceQuery;

export const query = graphql`
query($localeLang: String!) {
    pim {
        getReferencesConfigListing(defaultLanguage: $localeLang) {
            edges {
                node {
                    seotitle
                    seodescription
                    ogimage {
                        id
                        fullpath
                    }
                    button1URL
                    button1txt
                    button2URL
                    button2txt
                    pagesubtitle
                    pagetitle
                    projectssubtitle
                    projectstitle
                    projects {
                        ... on PIM_object_Project {
                            englishVersion
                            id
                            previewimage {
                                id
                                fullpath
                            }
                            projecttitle
                            projectsubtitle
                        }
                        ... on PIM_object_Projectnew {
                            englishVersion
                          id
                          previewimage {
                            id
                            fullpath
                          }
                          projecttitle
                          projectsubtitle
                        }
                    }
                    testimonialstitle
                    testimonialssubtitle
                    testimonials {
                        ... on PIM_object_Testimonial {
                            englishVersion
                            id
                            title
                            subtitle
                            text
                            previewimage {
                                id
                                fullpath
                            }
                        }
                    }
                    customerstitle
                    customers {
                        ... on PIM_object_CustomerLink {
                            id
                            URL
                            customer
                            logo {
                                id
                                fullpath
                            }
                        }
                    }
                }
            }
        }
    }
}
`;
