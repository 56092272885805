import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { useLocaleContext } from '@components/util/LanguageProvider';
import css from "./Showcase.module.scss";
import classNames from 'classnames';
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";

import { EmptyRender, doNotRenderIfEmpty, useLocalizedData } from '@components/util/LanguageHooks';

import { getFirstItemFromQuery } from "@util/mapper";
import { useUrlBuilder } from "@util/url-builder";


const FooterQuery = () => {
    const { __: t } = useLocaleContext();
    const { pim } = useStaticQuery(graphql`
                fragment FooterFragment on PIM_FooterConnection {
                    edges {
                        node {
                            Facebook
                            Linkedin
                            Instagram
                            MagentoURL
                            MagentoBadge {
                              id
                              fullpath
                            }
                            GumptionURL
                            GumptionBadge {
                              id
                              fullpath
                            }
                            PimcoreURL
                            PimcoreBadge {
                              id
                              fullpath
                            }
                            FooterContent {
                                ... on PIM_object_Section {
                                    id
                                    SectionContent {
                                        ... on PIM_object_Link {
                                            id
                                            Link
                                            Name
                                        }
                                    }
                                    SectionName
                                }
                            }
                            FooterText
                            Logo {
                                fullpath
                                id
                            }
                        }
                    }
                }
                query{
                    pim{
                        getFooterListing_nl_BE: getFooterListing(defaultLanguage: "nl_BE") {
                            ...FooterFragment
                        }
                        getFooterListing_en: getFooterListing(defaultLanguage: "en") {
                            ...FooterFragment
                        }
                    }
                }
    `);

    const getFooterListingQuery = useLocalizedData(pim, 'getFooterListing');

    const items = getFirstItemFromQuery(getFooterListingQuery);

    var magentoBadgeStyle = {
        backgroundImage: `url(${generateThumbnailUrl(items.MagentoBadge, "general_thumbnail")})`
    };

    var pimcoreBadgeStyle = {
        backgroundImage: `url(${generateThumbnailUrl(items.PimcoreBadge, "general_thumbnail")})`
    };

    var gumptionBadgeStyle = {
        backgroundImage: `url(${generateThumbnailUrl(items.GumptionBadge, "general_thumbnail")})`
    };

    const { buildLocalizedStaticUrl } = useUrlBuilder();
    if (getFooterListingQuery.doNotRender) { return <EmptyRender />; }


    return (
        <div className={css.container}>
            <div className={css.leftContainer}>
                <div className={css.footerText}>{items.FooterText}</div>
                <div className={css.socialsContainer}>
                    <div className={css.socialsContainerLeft}>
                        <div className={css.badgeContainer}>
                            {items.PimcoreBadge !== null ?
                                <div style={pimcoreBadgeStyle} className={classNames(css.badge, css.badgePimcore)}>
                                    {items.PimcoreURL
                                        ? <a title={t('Pimcore Gold Partner')} 
                                        href={buildLocalizedStaticUrl(items.PimcoreURL)}>
                                            <span>{t('Pimcore Gold Partner')}</span></a>
                                        : <span>{t('Pimcore Gold Partner')}</span>
                                    }
                                </div>
                                : ""
                            }

                            {items.MagentoBadge !== null ?
                                <div style={magentoBadgeStyle} className={classNames(css.badge, css.badgeAdobe)}>
                                    {items.MagentoURL
                                        ? <a title={t('Magento Solution Partner')} 
                                        href={buildLocalizedStaticUrl(items.MagentoURL)}>
                                            <span>{t('Magento Solution Partner')}</span></a>
                                        : <span>{t('Magento Solution Partner')}</span>
                                    }
                                </div>
                                : ""
                            }
                        </div>

                        <div className={css.socialLinks}>
                            <a title="linkedIn" href={items.Linkedin} target="_blank" rel="noreferrer">
                                <img
                                    loading="lazy"
                                    alt="linkedin"
                                    title="linkedin"
                                    width="30px"
                                    height="30px"
                                    src="/assets/icons/linkedin.svg"
                                />
                            </a>
                            <a title="facebook" href={items.Facebook} target="_blank" rel="noreferrer">
                                <img
                                    loading="lazy"
                                    alt="facebook"
                                    title="facebook"
                                    width="30px"
                                    height="30px"
                                    src="/assets/icons/facebookblue.svg"
                                />
                            </a>
                            <a title="instagram" href={items.Instagram} target="_blank" rel="noreferrer">
                                <img
                                    loading="lazy"
                                    alt="instagram"
                                    title="instagram"
                                    width="30px"
                                    height="30px"
                                    src="/assets/icons/instagram.svg"
                                />
                            </a>
                        </div>
                    </div>
                    <div className={css.socialsContainerRight}>

                        {items.GumptionBadge !== null ?
                            <div style={gumptionBadgeStyle} className={classNames(css.badge, css.badgeGumption)}>
                                {items.GumptionURL
                                    ? <a title={t('Gumption')} href={items.GumptionURL}><span>{t('Gumption')}</span></a>
                                    : <span>{t('Gumption')}</span>
                                }
                            </div>
                            : ""
                        }
                    </div>
                </div>
            </div>
            <ul className={css.navList}>
                {items.FooterContent.map((section) => {
                    return (
                        <li className={css.section} key={section.id}>
                            <span>{section.SectionName}</span>
                            <ul className={css.linkList}>
                                {section.SectionContent.map((link, index) => {
                                    return (
                                        <li className={css.link} key={index}>
                                            <Link
                                             to={buildLocalizedStaticUrl(link.Link)}
                                             >
                                                {link.Name}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        </div>
    )
};

export default FooterQuery;
