import React, { Fragment } from 'react';
import { useLocaleContext } from '@components/util/LanguageProvider';

const TranslateComponent = (props) => {
  const { __: t } = useLocaleContext();

  return (
    <Fragment>
      {t(props.text, props.vars)}
    </Fragment>
  )
};

export function __(string, vars = {}) {
  return <TranslateComponent text={string} vars={vars} />;
}