import React, { Component, Fragment } from "react";
import { LocalizedLink as Link } from "@components/util/LanguageProvider";

import classNames from "classnames";

import { __ } from "@i18n/util";
import css from "./Card.module.scss";

export class Card extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {
            labels,
            author,
            title,
            subtitle,
            image,
            text,
            url,
            className,
            variant
        } = this.props;

        return (
            <div className={classNames(css.card, className, css[variant])}>
                <Link to={url}>
                    <div className={css.cardContainer}>
                        <div className={css.cardWrapper}>

                            {labels && labels.length > 0 && (
                                <div className={classNames(css.labels)}>
                                    {labels.map((label, index) => (
                                        <div key={index} className={classNames(css.label)}>
                                            {label}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className={classNames(css.imageContainer)}>
                                <img alt={title} loading="lazy" src={image} />
                            </div>
                            <div className={classNames(css.content)}>
                                <div className={classNames(css.inner)}>
                                    {author && (
                                        <div className={classNames(css.author)}>
                                            {author ? (
                                                <Fragment>
                                                    {__("By")}
                                                    {` ${author}`}
                                                </Fragment>
                                            ) : (
                                                <Fragment />
                                            )}
                                        </div>
                                    )}

                                    {title && (
                                        <div className={classNames(css.title)}>{title}</div>
                                    )}

                                    {subtitle && (
                                        <div className={classNames(css.subtitle)}>{subtitle}</div>
                                    )}
                                    {text && (
                                        <div className={classNames(css.text)}>{text}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default Card;
