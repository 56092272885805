import React from 'react';
import ReactHelmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import { useLocation } from '@reach/router';
import { parseServerSideMeta } from '../../../util/dispatch';
import { useLocaleContext } from '../LanguageProvider';

import { store } from '@redux/provider';

const Helmet = (props) => {
    const { locale, title, description, location, image, url, structureddata, pageLocale } = props;
    
    return <ReactHelmet
        htmlAttributes={{
            lang: locale
        }}
        title={title}
        titleTemplate={`%s - Studio Emma`}
        meta={[
            {
                name: 'description',
                content: description
            },
            {
                property: 'og:site_name',
                content: 'Studio Emma'
            },
            {
                property: 'og:title',
                content: title
            },
            {
                property: 'og:description',
                content: description
            },
            {
                property: 'og:url',
                content: location
            },
            {
                property: 'og:locale',
                content: locale === 'nl' ? 'nl_BE' : 'en' // dirty fix before live
            },
            {
                property: 'og:image',
                content: image
            },
            {
                property: 'og:type',
                content: 'website'
            },
            {
                name: 'twitter:card',
                content: 'summary'
            },
            {
                name: 'twitter:creator',
                content: 'Studio Emma'
            },
            {
                name: 'twitter:title',
                content: title
            },
            {
                name: 'twitter:description',
                content: description
            }
        ]}
    >
        {pageLocale.nl_BE && <link href={`${process.env.SITE_URL}${pageLocale.nl_BE}`} hreflang="nl" rel='alternate' /> }

        {pageLocale.en && <link href={`${process.env.SITE_URL}${pageLocale.en}`} hreflang="en" rel="alternate" />
        }

        {pageLocale.en && <link href={`${process.env.SITE_URL}${pageLocale.en}`} hreflang="x-default" rel="alternate" />
        }

        {!pageLocale.en && <link href={`${process.env.SITE_URL}${pageLocale.nl_BE}`} hreflang="x-default" rel="alternate" />
        }

        <script type="application/ld+json">{structureddata}</script>
        {/* <!-- CookiePro Cookies Consent Notice start for studioemma.com --> */}
        {/* PRODUCTION SCRIPT */}
        <script src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="018f1590-d5bd-7834-9f3c-525b09c71fc2" ></script>
        <script type="text/javascript">
            {`
                function OptanonWrapper() {
                    const empty = 'have-something-so-build-does-not-remove-me';
                }
            `}
        </script>
        {/* <!-- CookiePro Cookies Consent Notice end for studioemma.com --> */}
    </ReactHelmet>

}

const locales = {
    'nl_BE': 'nl',
    'en': 'en'
};

export const SeoMetaTags = (props) => {
    const { localeLang, id, pageId, useLanguageSwitch, locale : locale2 } = useLocaleContext();
    
    console.log('localeLang', localeLang);
    console.log('id->', id);

    console.log('pageId->', pageId);
    console.log('useLanguageSwitch->', useLanguageSwitch);
    console.log('locale2->', locale2);

    const { pim, metadata, structureddata } = props;
    const meta = parseServerSideMeta(pim, metadata);

    const title = meta.title || 'Studio Emma';
    const description = meta.description || 'E-commerce expertise!';
    const image = (typeof meta.image === 'object') ? generateThumbnailUrl(meta.image, 'general_thumbnail') : '/assets/fallback.jpg';


    const location = process.env.SITE_URL + useLocation().pathname; // dirty fix before live
    const searchParameters = useLocation().search;
    const newUrl = (location) ? location.replace(searchParameters, '') : '/404';
    const locale = locales[localeLang];

    const usePageLocale = (pageId) => {
        const { pages } = store.getState();
        const page = pages[pageId];

        if (!page) {
            return { nl_BE: null, en: null };
        }

        return {
            nl_BE: page.nl_BE || null,
            en: page.en || null
        };
    };

    const pageLocale = usePageLocale(pageId);

    return (
        <Helmet
            structureddata={structureddata}
            title={title}
            description={description}
            image={image}
            locale={locale}
            url={newUrl}
            pageLocale={pageLocale}
            location={location}
        />
    );

}