import React, { Fragment } from "react";

import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import RichContent from "@components/common/RichContent/RichContent";
import CtaBlock from "@components/vacancies/CtaBlock/CtaBlock";
import { graphql } from "gatsby";
import { SeoMetaTags } from "../components/util/SOE/SOE";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getVacancy',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const Page = (props) => {
    const { data } = props;

    const {
        functiontitle,
        functiondescription,
        stucturedData
    } = data.pim.getVacancy;

    const config = data.pim.getVacanciesListing.edges[0].node;

    // Call to action
    const { solliciteernuCTA, solliciteernuCTAtitle, telephone, email } = config;

    return (
        <Fragment>
            <SeoMetaTags pim={data.pim} metadata={metadata} structureddata={stucturedData} />
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        vertical="center"
                        maxWidth="maxWidthSmall"
                    >
                        <Heading isSemantic={true} variant="h1" color="white">
                            {functiontitle}
                        </Heading>
                    </ContentWrapper>
                </Header>
                <ContentWrapper maxWidth="maxWidthMedium" isWide={true}>
                    <ContentWrapper paddedTop={true} paddedBottom={true}>
                        <RichContent>{functiondescription}</RichContent>
                    </ContentWrapper>
                    {/* <ContentWrapper paddedBottom={true}>
                        <Interviewflowsteps items={flowsteps} />
                    </ContentWrapper> */}
                </ContentWrapper>
                <ContentWrapper isWide={true}>
                    <CtaBlock
                        title={solliciteernuCTAtitle}
                        text={solliciteernuCTA}
                        telephone={telephone}
                        email={email}
                        backgroundImage
                    />
                </ContentWrapper>
            </ContentWrapper>
        </Fragment>
    );
};

export default Page;

export const query = graphql`
    query($id: Int!, $localeLang: String!) {
        pim {
            getVacancy(id: $id, defaultLanguage: $localeLang) {
                id
                functiontitle
                car
                functiondescription
                flowsteps {
                    ... on PIM_object_InterviewFlowStep {
                        id
                        step
                        icon {
                            id
                            fullpath
                        }
                    }
                }
                seotitle
                seodescription
                stucturedData
                ogimage {
                    id
                    fullpath
                }
            }
            getVacanciesListing(defaultLanguage: $localeLang) {
                edges {
                    node {
                        email
                        telephone
                        solliciteernuCTA
                        solliciteernuCTAtitle
                        caroussel {
                            image {
                                id
                                fullpath
                            }
                        }
                    }
                }
            }
        }
    }
`;
