import React, { Component } from 'react'
import classNames from "classnames";
import { LocalizedLink as Link } from "@components/util/LanguageProvider";
import { __ } from "@i18n/util";

import RichContent from "@components/common/RichContent/RichContent";
import css from './InfoBox.module.scss';

export class InfoBoxItem extends Component {
    render() {

        const { infoTitle, infoText, infoDate, url, col, bgColor, margin, isWide } = this.props;
        return (
            <div className={classNames(css.infoBoxItem, css[col], css[bgColor], css[margin], css[isWide])}>
                {infoTitle && (
                                <div className={css.title}>{infoTitle}</div>
                                )}
                {infoDate && (
                                <div className={css.date}>
                                    { infoDate }
                                </div>
                                )}
                <RichContent>{ infoText }</RichContent>
                {url && (
                                <div className={css.link}>
                                    <Link to={url}>{__("More info and subscribe")}</Link>
                                </div>
                                )}
            </div>
        )
    }
}

export default InfoBoxItem
