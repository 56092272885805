import React, { Fragment } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { __ } from "@i18n/util";
import css from "@styles-pages/contact.module.scss";
import { SeoMetaTags } from "../components/util/SOE/SOE";

import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import Map from "@components/common/Map/Map";
import RichContent from "@components/common/RichContent/RichContent";
import ContentWrapper from "../components/common/ContentWrapper/ContentWrapper";
import ColumnGroup from "../components/common/ColumnGroup/ColumnGroup";
import Column from "../components/common/Column/Column";
import Header from "../components/common/Header/Header";
import Heading from "../components/common/Heading/Heading";
import ContactForm from "../components/contact/ContactForm/ContactForm";
import ContactInformation from "../components/contact/ContactInformation/ContactInformation";
import classnames from "classnames"
import Fade from '@components/util/Fade';

/* eslint-disable no-undef */
export const metadata = {
    query: 'getContactConfig',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const ContactQuery = (props) => {

    const { data } = props;
    const pim = data.pim;

    const {
        title,
        subtitle,
        email,
        phonenumber,
        locationtitle,
        locations,
        stucturedData
    } = pim.getContactConfig;

    const mailtovalue = "mailto:" + email;
    const phonevalue = "tel:" + phonenumber;

    return (
        <Fragment>
            <SeoMetaTags pim={pim} metadata={metadata} structureddata={stucturedData} />
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading isSemantic={true} variant="h1" color="white">
                                {title}
                            </Heading>
                            <HighlightedText color="white">
                                {subtitle}
                            </HighlightedText>
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                <Fade duration={2000}>
                    <ContentWrapper paddedTop={true} paddedBottom={true}>
                        <ContactInformation
                            items={[
                                {
                                    label: __("Phone"),
                                    text: phonenumber,
                                    value: phonevalue,
                                },
                                {
                                    label: __("E-mail"),
                                    text: email,
                                    value: mailtovalue,
                                },
                            ]}
                        />
                        <ContactForm
                            variant="contact"
                            endpoint={`${process.env.PIMCORE_BASE_URL}${process.env.PIMCORE_CONTACT_ENDPOINT}`}
                            errorMessage={__("Looks like something went wrong, please try again!")}
                            successMessage={__("We have received your request and we will contact you shortly.")}
                            resetMessage={__("Back to form")} />
                    </ContentWrapper></Fade>
                <Fade duration={2000}>
                    <ContentWrapper maxWidth="maxWidthLarge">
                        <h2 className={classnames(css.title, css.centeredText)}>{locationtitle}</h2>
                        {locations.map((location) => {
                            return (
                                <ColumnGroup className={css.locationContainer} halign="halignbetween" valign="valigncenter" wrap="wrap" type="typerow">
                                    <Column className={css.infoContainer} width="width100" widthmd="widthmd40">
                                        <h3 className={css.locationTitle}>
                                            {location.title}
                                        </h3>
                                        <p className={css.descriptionText}>
                                            {location.descriptionText}
                                        </p>
                                        <div>
                                            <ul className={css.infoList}>
                                                <li className={css.infoListItem}>
                                                    <RichContent>
                                                        {location.address}
                                                    </RichContent>
                                                </li>
                                                <li className={css.infoListItem}>
                                                    <a
                                                        className={css.link}
                                                        href={
                                                            "tel:" + location.phone
                                                        }
                                                    >
                                                        <img
                                                            loading="lazy"
                                                            alt="phone"
                                                            title="phone"
                                                            width="15px"
                                                            height="15px"
                                                            src="/assets/icons/phone.svg"
                                                            className={css.img}
                                                        />

                                                        {" " + location.phone}
                                                    </a>
                                                </li>
                                                <li className={css.infoListItem}>
                                                    <a
                                                        className={css.link}
                                                        href={
                                                            "mailto:" +
                                                            location.email
                                                        }
                                                    >
                                                        <img
                                                            loading="lazy"
                                                            alt="envelope"
                                                            title="envelope"
                                                            width="15px"
                                                            height="15px"
                                                            src="/assets/icons/envelope.svg"
                                                            className={css.img}
                                                        />
                                                        {" " + location.email}
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className={css.billingInfo}>
                                                <RichContent>
                                                    {location.billinginfo}
                                                </RichContent>
                                            </div>
                                        </div>
                                    </Column>
                                    <Column className={css.map} width="width100" widthmd="widthmd50">
                                        <Map
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        location={{
                                            lat: location.latitude,
                                            lng: location.longitude,
                                        }}
                                    />
                                    </Column>
                                </ColumnGroup>
                            );
                        })}
                    </ContentWrapper>
                </Fade>
            </ContentWrapper>
        </Fragment>
    );
};


export default ContactQuery;

export const query = graphql`
    query($localeLang: String!) {
        pim {
                getContactConfig(id: 161, defaultLanguage: $localeLang) {
                    phonenumber
                    seotitle
                    seodescription
                    stucturedData
                    subtitle
                    ogimage {
                        id
                        fullpath
                    }
                    title
                    email
                    locations {
                        ... on PIM_object_Location {
                            id
                            email
                            title
                            phone
                            description
                            billinginfo
                            address
                            latitude
                            longitude
                        }
                    }
                    locationtitle
                }
            }
    }`;