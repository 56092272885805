import React from "react";

import { LocalizedLink as Link } from "@components/util/LanguageProvider";

import classNames from "classnames";
import css from "./Logo.module.scss";
// import "./LogoAnimation.scss";

import { setIsMobileNavigationOpen as setIsMobileNavigationOpenAction } from "@redux/actions";
import { setIsStickyNavigationOpen as setIsStickyNavigationOpenAction } from "@redux/actions";
import { connect } from "react-redux";

import { useLocaleContext } from "@components/util/LanguageProvider";

export const Logo = (props) => {
    const { useDefaultTheme, isStickyNavigationOpen } = props;
    const type = useDefaultTheme ? "default" : "silhouette";
    const { locale } = useLocaleContext();
    const ureLocalizedSite = locale === 'en' ? '/en/' : '/';

    return (
        <Link
            onClick={() => props.setIsMobileNavigationOpen(false)}
            to={`${ureLocalizedSite}`}
            className={classNames(
                css.logo,
                css[type],
                {[css.hidden]: props.isMobileNavigationOpen},
                typeof window !== "undefined" && {
                    [css.mobileSticky]:
                        isStickyNavigationOpen && window.innerWidth < 1000,
                }
            )}
        >
            <span>Studio Emma - Home</span>
        </Link>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setIsMobileNavigationOpen: (boolean) =>
        dispatch(setIsMobileNavigationOpenAction(boolean)),
    setIsStickyNavigationOpen: (boolean) =>
        dispatch(setIsStickyNavigationOpenAction(boolean)),
});

const mapStateToProps = (globalState) => ({
    isMobileNavigationOpen: globalState.isMobileNavigationOpen,
    isStickyNavigationOpen: globalState.isStickyNavigationOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
