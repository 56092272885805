
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Article from "@components/common/Article/Article";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import FeaturedService from "@components/services/FeaturedService/FeaturedService";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import LinkButton from "@components/common/LinkButton/LinkButton";
import LinkButtonRow from "@components/common/LinkButtonRow/LinkButtonRow";
import urlBuilder from "@util/url-builder";
import css from "@styles-pages/services.module.scss";
import { checkButton } from '@util/check-button';
import { SeoMetaTags } from "../components/util/SOE/SOE";
import Fade from '@components/util/Fade';

import { useEnableFlag } from '@components/util/LanguageHooks';



/* eslint-disable no-undef */
export const metadata = {
    query: 'getServicesConfigListing',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const FeaturedServicesSection = ({ featuredServices }) => (
    <ContentWrapper paddedBottom={true} isWide={true}>
        <div className={css.featuredContainer}>
            {featuredServices.map((service) => (
                <FeaturedService
                    key={service.id}
                    title={service.title}
                    listingtext={service.listingtext}
                    logo={service.logo}
                    url={urlBuilder.buildStaticUrl(service.URL)}
                />
            ))}
        </div>
    </ContentWrapper>
);

const ServicesQuery = (props) => {

    const { data } = props;
    const pim = data.pim;

    const filteredServicesConfigListing = useEnableFlag(pim.getServicesConfigListing);
    console.log(filteredServicesConfigListing, 'filteredServicesConfigListing in diensten.js');

    //Page header
    const { title, subtitle } = filteredServicesConfigListing.edges[0].node;

    //Featured services
    const featuredServices =
    filteredServicesConfigListing.edges[0].node.featuredservices;

    //Other services
    const {
        otherservicestitle,
        otherservicessubtitle,
        otherservicesPicture,
        button1URL,
        button1txt,
        button2URL,
        button2txt,
        stucturedData
    } = filteredServicesConfigListing.edges[0].node;

    const isBtn1 = checkButton(button1txt, button1URL);
    const isBtn2 = checkButton(button2txt, button2URL);

    return (
        <>
            <SeoMetaTags pim={pim} metadata={metadata} structureddata={stucturedData}/>
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                        vertical="center"
                    >
                        <ContentWrapper
                            className={css.introduction}
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                {title}
                            </Heading>
                            <HighlightedText color="white">
                                {subtitle}
                            </HighlightedText>

                            {(isBtn1 || isBtn2) && (
                                <LinkButtonRow>
                                    {isBtn1 && (
                                        <LinkButton
                                            link={urlBuilder.buildStaticUrl(button1URL)}
                                            variant="orangeRevert"
                                            text={button1txt}
                                        />
                                    )}

                                    {isBtn2 && (
                                        <LinkButton
                                            link={urlBuilder.buildStaticUrl(button2URL)}
                                            variant="orangeRevert"
                                            text={button2txt}
                                        />
                                    )}
                                </LinkButtonRow>
                            )}
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                <Fade duration={2000}>
                    <ContentWrapper>
                        <FeaturedServicesSection
                            featuredServices={featuredServices}
                        />
                    </ContentWrapper></Fade>
                <Fade duration={2000}>
                    <ContentWrapper paddedTop paddedBottom>
                        <Article
                            reverse
                            title={otherservicestitle}
                            text={otherservicessubtitle}
                            mediaSrc={otherservicesPicture}
                            type="image"
                        >
                        </Article>
                    </ContentWrapper></Fade>
            </ContentWrapper>
        </>
    );

};

export default ServicesQuery;

export const query = graphql`
    query($localeLang: String!) {
        pim {
            getServicesConfigListing(defaultLanguage: $localeLang) {
                edges {
                    node {
                        title
                        subtitle
                        seotitle
                        seodescription
                        ogimage {
                            id
                            fullpath
                        }
                        button1URL
                        button1txt
                        button2URL
                        button2txt
                        featuredservices {
                            ... on PIM_object_FeaturedService {
                                id
                                listingtext
                                logo {
                                    id
                                    fullpath
                                }
                                URL
                                pagecontent
                                seodescription
                                seotitle
                                stucturedData
                                subtitle
                                title
                                project {
                                    ... on PIM_object_Project {
                                        englishVersion
                                        id
                                        previewimage {
                                            id
                                            fullpath
                                        }
                                        projecttitle
                                    }
                                    ... on PIM_object_Projectnew {
                                        englishVersion
                                        id
                                        previewimage {
                                            id
                                            fullpath
                                        }
                                        projecttitle
                                    }
                                }
                            }
                        }
                        otherservicestitle
                        otherservicessubtitle
                        otherservicesbuttontitle
                        OtherservicesURL
                        otherservicesPicture {
                          id
                          fullpath
                        }
                    }
                }
            }
        }
    }
`;
