import { store } from '@redux/provider';
import { useLocaleContext } from '../components/util/LanguageProvider';
const kebabCase = require("../util/page-builder").kebabCase;
const kebabCase2 = require("../util/page-builder").kebabCase2;

export const urlBuilder = {

    buildLocalizedStaticUrl: function (path, locale = 'nl_BE') {

        if (locale === 'nl_BE') {
            if(!path) return '';
            return `/${kebabCase2(path)}`;
        } else if (locale === 'en') {
            if(!path) return '';
            return `/en/${kebabCase2(path)}`;
        }

    },
    buildStaticUrl: function (path) {
        return `/${kebabCase2(path)}`;
    },

    buildDynamicUrl: function (id, locale = 'nl_BE') {
        const { pages } = store.getState();

        if (pages === undefined || pages[id] === undefined) return '/404';

        let path = [];

        if (pages[id][locale] === undefined) {
            path = ["", "en/not-available", ""];
        } else {
            path = pages[id][locale].split('/');
        }

        // TODO: find out what we do here? - next 7 lines
        if (path.length > 1) {
            path[path.length - 1] = kebabCase(path[path.length - 1]);
        }

        const formattedPath = `${path.join('/')}/`;

        return formattedPath;
    }
}

export default urlBuilder;

export const useUrlBuilder = () => {
    const { locale } = useLocaleContext();

    return {
        buildStaticUrl: urlBuilder.buildStaticUrl,
        buildDynamicUrl: (id) => urlBuilder.buildDynamicUrl(id, locale),
        buildLocalizedStaticUrl: (path) => urlBuilder.buildLocalizedStaticUrl(path, locale)
    }
}