import classNames from 'classnames'
import React from 'react'
import css from "@styles-pages/landingspage.module.scss";
import { useLocaleContext } from '@components/util/LanguageProvider';
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";

function UspsList(props) {
    let obj = props.data
    const { __: t } = useLocaleContext();

    return (
        <>
            <ul>
                {obj.map((item) => {
                    return (
                        <>
                            <li>
                                {item.USPimage ? (
                                    <div className={css.uspNumber}>
                                        <img className={css.uspImage}
                                            loading="lazy"
                                            src={generateThumbnailUrl(
                                                item.USPimage,
                                                "logo_thumbnail"
                                            )}
                                            alt={`${t("Icon about")} ${item.USPdescription}`}
                                        />
                                    </div>
                                ) : (<div className={css.uspNumber}>{item.USPnumber}</div>)}
                                <div className={css.uspDescription}>
                                    {item.USPdescription}
                                </div>
                            </li>
                        </>
                    )

                })}
            </ul>
        </>
    )
}

export default UspsList