import { graphql, useStaticQuery } from "gatsby";
import React, { createContext, useContext, Fragment } from 'react';
import { Link } from "gatsby"

export const LanguageContext = createContext({
    locale: undefined,
    pageId: undefined,
    queryLanguageSwitch: undefined,
});

export const useLocaleContext = () => useContext(LanguageContext);

export const LanguageProvider = ({ children, localeLang, pageId, useLanguageSwitch }) => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getDutchTranslations: getTranslationListing(defaultLanguage: "nl_BE") {
                    edges {
                        node {
                            KeyText
                            Translation
                        }
                    }
                }
                getEnglishTranslations: getTranslationListing(defaultLanguage: "en") {
                    edges {
                        node {
                            KeyText
                            Translation
                        }
                    }
                }
            }
        }
    `);

    const dictionary = {
        nl_BE: {},
        en: {}
    };

    for (let i = 0; i < pim.getDutchTranslations.edges.length; i++) {
        const node = pim.getDutchTranslations.edges[i].node;
        dictionary.nl_BE[node.KeyText] = node.Translation;
    }

    for (let i = 0; i < pim.getEnglishTranslations.edges.length; i++) {
        const node = pim.getEnglishTranslations.edges[i].node;
        dictionary.en[node.KeyText] = node.Translation;
    }

    return <LanguageContext.Provider value={
        {
            locale: localeLang,
            pageId: pageId,
            useLanguageSwitch: useLanguageSwitch,
            __: (key, values = {}) => {
                if (!dictionary[localeLang]) {
                    return key;
                }

                let translatedkey = dictionary[localeLang][key] ?? key;
                for (const [key, value] of Object.entries(values)) {
                    translatedkey = translatedkey.replaceAll(`{{${key}}}`, value);
                }
                return translatedkey
            }
        }
    }>{children}</LanguageContext.Provider>
}

// TODO: add locale to link
export const LocalizedLink = (props) => {
    const { locale } = useLocaleContext();
    const { to, ...rest } = props;

    return <Link to={to} {...rest} />;
}

export default LanguageProvider;