import React from "react";
import { LocalizedLink as Link } from "@components/util/LanguageProvider";
import css from "./Links.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import { stringToUrl } from '@util/string-to-url';
import { useUrlBuilder } from "@util/url-builder";

import { useLocaleContext } from "@components/util/LanguageProvider";

const useLinksQuery = () => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getPageListing(defaultLanguage: "auto", filter: "{\"$or\": [{\"pageReference\": \"Cookiepolicy\"}, {\"pageReference\": \"privacy\"}, {\"pageReference\": \"conditions\"} ]}") {
                    edges {
                        node {
                            pageUrlKey
                            pageIntroTitle
                        }
                    }
                }
            }
        }
    `);

    return pim.getPageListing.edges.map(({ node }) => ({
        text: node.pageIntroTitle,
        url: node.pageIntroTitle,
    }));
};

const Links = () => {
    const links = useLinksQuery();

    const { buildLocalizedStaticUrl } = useUrlBuilder();

    const { locale } = useLocaleContext();

    if(locale === 'en'){
        const EngStaticLinks = [
            { url: 'privacy-policy', text: 'Privacy Policy' },
            { url: 'conditions', text: 'Conditions' },
            { url: 'cookie-policy', text: 'Cookie Policy' }
        ];

        return (
            <div className={css.links}>
            {EngStaticLinks.map((link, index) => (
                <Link key={index}
                 to={buildLocalizedStaticUrl(link.url)}
                  title={link.text}>
                {link.text}
                </Link>
            ))}
            </div>
        );
    }

    return (
        <div className={css.links}>
            {links.map((link, index) => (
                <Link key={index}
                 to={buildLocalizedStaticUrl(link.url)}
                  title={link.text}>
                    {link.text}
                </Link>
            ))}
        </div>
    );
};

export default Links;