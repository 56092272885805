
import React from "react";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import { graphql, useStaticQuery } from "gatsby";
import css from "@styles-pages/team.module.scss";

import JobCard from "@components/common/JobCard/JobCard";
import JobCardGrid from "@components/common/JobCardGrid/JobCardGrid";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import { SeoMetaTags } from "../components/util/SOE/SOE";
import Fade from '@components/util/Fade';



/* eslint-disable no-undef */
export const metadata = {
    query: 'getTeamConfigListing',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const TeamQuery = (props) => {

    const pim = props.data.pim;

    const {
        firstpillartitle,
        firstpillartext,
        secondpillartitle,
        secondpillartext,
        thirdpillartitle,
        thirdpillartext,
        title,
        subtitle,
        teammembers,
        stucturedData
    } = pim.getTeamConfigListing.edges[0].node;

    return (
        <>
            <SeoMetaTags pim={pim} metadata={metadata} structureddata={stucturedData} />
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                        vertical="center"
                        maxWidth="maxWidthSmall"
                    >
                        <ContentWrapper
                            className={css.introduction}
                            isWide={true}
                        >
                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                {title}
                            </Heading>
                            <HighlightedText color="white">
                                {subtitle}
                            </HighlightedText>
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                <Fade duration={2000}>
                    <ContentWrapper paddedTop paddedBottom>
                        <JobCardGrid>
                            {teammembers.map((item) => (
                                <JobCard
                                    key={item.id}
                                    title={item.name}
                                    freeLink={item.freeLink}
                                    image={generateThumbnailUrl(
                                        item.portrait,
                                        "team_member_thumbnail"
                                    )}
                                    imageHover={generateThumbnailUrl(
                                        item.portrait2,
                                        "team_member_thumbnail"
                                    )}
                                    text={item.expertise}
                                />
                            ))}
                        </JobCardGrid>
                    </ContentWrapper></Fade>
                <Fade duration={2000}>
                    <ContentWrapper paddedTop paddedBottom maxWidth="maxWidthMedium">
                        <div className={css.pillar}>
                            <h3 className={css.pillarTitle}>{firstpillartitle}</h3>
                            <p className={css.pillarText}>{firstpillartext}</p>
                        </div>
                        <div className={css.pillar}>
                            <h3 className={css.pillarTitle}>{secondpillartitle}</h3>
                            <p className={css.pillarText}>{secondpillartext}</p>
                        </div>
                        <div className={css.pillar}>
                            <h3 className={css.pillarTitle}>{thirdpillartitle}</h3>
                            <p className={css.pillarText}>{thirdpillartext}</p>
                        </div>
                    </ContentWrapper></Fade>
            </ContentWrapper>
        </>
    );
};

export default TeamQuery;

export const query = graphql`
    query($localeLang: String!) {
            pim {
            getTeamConfigListing(defaultLanguage: $localeLang) {
                edges {
                    node {
                        firstpillartext
                        firstpillartitle
                        secondpillartext
                        secondpillartitle
                        thirdpillartext
                        thirdpillartitle
                        seotitle
                        seodescription
                        stucturedData
                        ogimage {
                            id
                            fullpath
                        }
                        subtitle
                        title
                        teammembers {
                            ... on PIM_object_TeamMember {
                                id
                                name(language: "nl_BE")
                                expertise(language: $localeLang)
                                portrait {
                                    id
                                    fullpath
                                }
                                portrait2 {
                                    id
                                    fullpath
                                }
                                freeLink
                            }
                        }
                        id
                    }
                }
            }
        }
    }`;
