import React from "react";
import { LocalizedLink as Link } from "@components/util/LanguageProvider";
import { generateLayout } from "@util/generate-layout";
import css from "./LinkButton.module.scss";
import classNames from "classnames";
import { checkButton } from "../../../util/check-button";



export class LinkButton extends React.Component {
    //button should have 3 styles (variants) and a link and a buttontext
    render() {
        const { link, variant, text, uppercase, marginTop, marginSides, onClick } = this.props;

        const layout = generateLayout({
            marginTop,
            marginSides
        });

        let textTransform = "none";
        if (uppercase) {
            textTransform = "uppercase";
        }

        if (!text || !link || text === "" || link === "" || text === undefined || link === undefined || text === null || link === null || link === "/") {
            return null;
        }


        return (
            <Link to={link}>
                <div onClick={onClick} className={classNames(css.button, css[variant], css[layout.marginTop], css[layout.marginSides])}>
                    <span
                        className={classNames(
                            css.text,
                            css[textTransform]
                        )}
                    >
                        {text}
                    </span>
                    <span className={css.arrow} style={{paddingLeft: '1.5rem'}}>  
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M7.76639 4.38118L4.96678 2.3548C4.91878 2.31445 4.87318 2.27305 4.83178 2.22746C4.75018 2.13576 4.68598 2.03305 4.64218 1.92353C4.49338 1.55357 4.59298 1.13174 4.89778 0.843524C4.98778 0.758633 5.09338 0.686318 5.21038 0.630772C5.36758 0.555313 5.53738 0.516536 5.71558 0.501863C5.78218 0.499767 5.78158 0.499767 5.84818 0.500291C6.02638 0.511296 6.19738 0.545881 6.35698 0.617672C6.43678 0.653305 6.50818 0.697323 6.57838 0.745008L11.3742 4.21192C11.4492 4.2811 11.478 4.30415 11.5422 4.37332C11.6496 4.48808 11.7402 4.6149 11.8116 4.74904C11.9274 4.96756 11.9898 5.20337 11.9982 5.44075C12.0006 5.47848 12.0006 5.51569 11.9982 5.55342C11.9892 5.82171 11.9118 6.08844 11.7666 6.32897C11.7036 6.43429 11.628 6.53386 11.5422 6.62556C11.478 6.69473 11.4492 6.71779 11.3742 6.78696L6.57778 10.2544C6.52498 10.29 6.47218 10.3241 6.41458 10.354C6.28078 10.4221 6.13258 10.4677 5.97958 10.4881C5.84818 10.5054 5.71378 10.5038 5.58298 10.4834C5.14138 10.4153 4.76938 10.1323 4.63138 9.7592C4.58338 9.63029 4.56418 9.49405 4.57498 9.35885C4.58518 9.22418 4.62478 9.09107 4.69198 8.96898C4.76938 8.82749 4.88098 8.70906 5.01598 8.60636L7.78859 6.60198C5.60038 6.60408 3.41158 6.56897 1.22337 6.56897C1.16337 6.56792 1.14837 6.56844 1.08897 6.56268C0.95457 6.54958 0.82317 6.51709 0.70077 6.46678C0.558569 6.40809 0.429569 6.3253 0.322768 6.22416C0.0293677 5.94538 -0.0744326 5.54136 0.0545678 5.18031C0.0881679 5.08651 0.136768 4.99691 0.198568 4.91464C0.272368 4.8156 0.364768 4.72808 0.471569 4.65577C0.613769 4.55883 0.77997 4.49018 0.95577 4.45612C1.02237 4.44302 1.08837 4.43673 1.15617 4.43149C3.35878 4.37856 5.56318 4.36179 7.76639 4.38118Z" fill="currentColor"/>
</svg>

                    </span>
                </div>
            </Link>
        );
    }
}

export default LinkButton;
