
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import ColumnGroup from "@components/common/ColumnGroup/ColumnGroup";
import Column from "@components/common/Column/Column";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import LinkButton from "@components/common/LinkButton/LinkButton";
import RichContent from "@components/common/RichContent/RichContent";
import VideoPlayer from "@components/common/VideoPlayer/VideoPlayer";
import Article from "@components/common/Article/Article";
import LinkButtonRow from "@components/common/LinkButtonRow/LinkButtonRow";

import { __ } from "@i18n/util";
import css from "@styles-pages/studio-emma.module.scss";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import React, { Fragment, useState } from "react";
import urlBuilder from "@util/url-builder";
import { checkButton } from '@util/check-button';
import { SeoMetaTags } from "../components/util/SOE/SOE";
import Fade from '@components/util/Fade';
import { useUrlBuilder } from "@util/url-builder";



/* eslint-disable no-undef */
export const metadata = {
    query: 'getStudioEmmaConfigListing',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const StudioEmmaQuery = (props) => {

    const { data } = props;
    const pim = data.pim;

    const {
        title,
        subtitle,
        button1URL,
        button1txt,
        button2URL,
        button2txt,
        studioemmatitle,
        featuredservicestitle,
        featuredservicestext,
        featuredservicesphoto,
        servicesURL,
        mantratitle,
        mantratext,
        mantraURL,
        kompasURL,
        compasstitle,
        compasstext,
        teamtitle,
        teamtext,
        teamvideoid,
        teamURL,
        stucturedData
    } = pim.getStudioEmmaConfigListing.edges[0].node;

    const isBtn1 = checkButton(button1txt, button1URL);
    const isBtn2 = checkButton(button2txt, button2URL);

    const { buildLocalizedStaticUrl } = useUrlBuilder();

    return (
        <Fragment>
            <SeoMetaTags pim={pim} metadata={metadata} structureddata={stucturedData} />
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                        vertical="center"
                    >
                        <ContentWrapper
                            className={css.introduction}
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                {title}
                            </Heading>
                            <HighlightedText
                                color="white"
                            >
                                {subtitle}
                            </HighlightedText>

                            {(isBtn1 || isBtn2) && (
                                <LinkButtonRow>
                                    {isBtn1 && (
                                        <LinkButton
                                            link={buildLocalizedStaticUrl(button1URL)}
                                            variant="orangeRevert"
                                            text={button1txt}
                                        />
                                    )}

                                    {isBtn2 && (
                                        <LinkButton
                                            link={buildLocalizedStaticUrl(button2URL)}
                                            variant="orangeRevert"
                                            text={button2txt}
                                        />
                                    )}
                                </LinkButtonRow>
                            )}
                        </ContentWrapper>
                    </ContentWrapper>
                </Header><Fade duration={2000}>
                    <ContentWrapper paddedTop paddedBottom isWide>
                        <ContentWrapper maxWidth="maxWidthMedium">
                            <h2
                                className={classNames(
                                    css.sectionTitle,
                                    css.centeredText
                                )}
                            >
                                {teamtitle}
                            </h2>
                            <div className={css.centeredText}>
                                <RichContent>{teamtext}</RichContent>
                            </div>
                            {teamvideoid &&
                                <VideoPlayer videoLink={teamvideoid} />
                            }
                        </ContentWrapper>
                        <ContentWrapper paddedTop>
                            <div className={css.linkButton}>
                                <LinkButton
                                    variant="blue"
                                    text={__("Our team members")}
                                    link={buildLocalizedStaticUrl(teamURL)}
                                    uppercase={false}
                                />
                            </div>
                        </ContentWrapper>
                    </ContentWrapper></Fade>
                <Fade duration={2000}>
                    <ContentWrapper paddedTop bgColor="bgColorLightBlue" maxWidth="maxWidthMax" isDesktopWide>
                        <h2
                            className={classNames(
                                css.sectionTitle,
                                css.centeredText
                            )}
                        >
                            {studioemmatitle}
                        </h2>
                        <ContentWrapper paddedTop isWide>
                            <div className={css.topic}>
                                <div
                                    className={classNames(
                                        css.cardText
                                    )}
                                >

                                    <ContentWrapper isWide paddedBottom maxWidth="maxWidthMedium">
                                        <Article
                                            title={featuredservicestitle}
                                            text={featuredservicestext}
                                            mediaSrc={featuredservicesphoto}
                                            type="image"
                                        >
                                            <LinkButton
                                                text={__("Read more")}
                                                link={buildLocalizedStaticUrl(servicesURL)}
                                                uppercase={false}
                                            />
                                        </Article>
                                    </ContentWrapper>
                                </div>
                            </div>
                        </ContentWrapper>
                    </ContentWrapper></Fade>
                <Fade duration={2000}>
                    <ContentWrapper paddedTop paddedBottom isWide>
                        <ContentWrapper maxWidth="maxWidthMedium" isWide>
                            <ColumnGroup halign="halignbetween">
                                <Column widthmd="widthsm100" widthlg="widthmd45">
                                    <ContentWrapper paddedBottom isDesktopWide>
                                        <div className={css.topic}>
                                            <h3
                                                className={classNames(
                                                    css.cardTitle
                                                )}
                                            >
                                                {mantratitle}
                                            </h3>
                                            <div
                                                className={classNames(
                                                    css.cardText
                                                )}
                                            >
                                                <RichContent>{mantratext}</RichContent>
                                            </div>
                                            <LinkButton
                                                text={__("Read more")}
                                                link={buildLocalizedStaticUrl(mantraURL)}
                                                uppercase={false}
                                                marginTop
                                            />
                                        </div>
                                    </ContentWrapper>
                                </Column>
                                <Column widthmd="widthsm100" widthlg="widthmd45">
                                    <ContentWrapper paddedBottom isDesktopWide>
                                        <div className={css.topic}>
                                            <h3
                                                className={classNames(
                                                    css.cardTitle
                                                )}
                                            >
                                                {compasstitle}
                                            </h3>
                                            <div
                                                className={classNames(
                                                    css.cardText
                                                )}
                                            >
                                                <RichContent>{compasstext}</RichContent>
                                            </div>
                                            <LinkButton
                                                text={__("Read more")}
                                                link={buildLocalizedStaticUrl(kompasURL)}
                                                uppercase={false}
                                                marginTop
                                            />
                                        </div>
                                    </ContentWrapper>
                                </Column>
                            </ColumnGroup>
                        </ContentWrapper>
                    </ContentWrapper></Fade>
            </ContentWrapper>
        </Fragment>
    );
};

const ServiceItem = ({ item }) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <li
            key={item.id}
            className={css.listItem}
            role="presentation"
            onClick={() => {
                setCollapsed(!collapsed);
            }}
            onKeyDown={() => {
                setCollapsed(!collapsed);
            }}
        >
            <div className={css.listTitle}>
                {item.title}{" "}
                <img
                    loading="lazy"
                    alt="envelope"
                    title="envelope"
                    width="30px"
                    height="30px"
                    src="/assets/icons/envelope.svg"
                    className={classNames({ [css.up]: collapsed }, css.img)}
                />
            </div>
            <p className={classNames(css.listText, { hidden: !collapsed })}>
                {item.text}
            </p>
        </li>
    );
};

export default StudioEmmaQuery;

export const query = graphql`
query($localeLang: String!) {
            pim {
                getStudioEmmaConfigListing(defaultLanguage: $localeLang) {
                    edges {
                        node {
                            button1URL
                            button1txt
                            button2URL
                            button2txt

                            vacanciesURL
                            teamURL
                            servicesURL
                            compasstext
                            compasstitle
                            kompasURL
                            featuredservicestext
                            featuredservicestitle
                            featuredservicesphoto {
                              id
                              fullpath
                            }
                            id
                            mantratext
                            mantratitle
                            mantraURL
                            name
                            occupation
                            otherservicestext
                            otherservicestitle
                            portrait {
                                id
                                fullpath
                            }
                            studioemmatitle
                            subtitle
                            teamtext
                            teamtitle
                            teamvideoid
                            testimonial
                            testimonialtitle
                            title
                            titleimage {
                                id
                                fullpath
                            }
                            seotitle
                            seodescription
                            stucturedData
                            ogimage {
                                id
                                fullpath
                            }
                        }
                    }
                }
                getServicesConfigListing(defaultLanguage: $localeLang) {
                    edges {
                        node {
                            otherservices {
                                ... on PIM_object_OtherService {
                                    id
                                    text
                                    title
                                }
                            }
                        }
                    }
                }
                getFeaturedServiceListing(defaultLanguage: $localeLang) {
                    edges {
                        node {
                            logo {
                                id
                                fullpath
                            }
                        }
                    }
                }
                getHomeConfigListing(defaultLanguage: $localeLang) {
                    edges {
                        node {
                            blogURL
                            onsprocesURL
                            projectsURL
                            testimonialsURL
                        }
                    }
                }
            }
        }
`;
