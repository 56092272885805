
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { __ } from "@i18n/util";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import css from "@styles-pages/magento.module.scss";
import urlBuilder from "@util/url-builder";

import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import RichContent from "@components/common/RichContent/RichContent";
import LinkButton from "@components/common/LinkButton/LinkButton";
import LinkButtonRow from "@components/common/LinkButtonRow/LinkButtonRow";
import UspGrid from "@components/common/Usp/UspGrid";
import UspItem from "@components/common/Usp/UspItem";
import Quote from "@components/common/Quote/Quote";
import InfoBoxGrid from "@components/common/InfoBox/InfoBoxGrid";
import InfoBoxItem from "@components/common/InfoBox/InfoBoxItem";
import Card from "@components/common/Card/Card";
import CardGrid from "@components/common/CardGrid/CardGrid";
import { SeoMetaTags } from "../components/util/SOE/SOE";
import Fade from '@components/util/Fade';



/* eslint-disable no-undef */
export const metadata = {
    query: 'getFeaturedService',
    title: 'seotitle',
    description: 'seodescription',
    image: 'ogimage',
    structureddata: 'stucturedData'
};

const Page = (props) => {
    const { data } = props;

    const {
        title,
        subtitle,
        backgroundColor,
        backgroundimage,
        pagecontent,
        featuredItems,
        quote,
        quotesignedby,
        pagecontent2,
        blueBoxes,
        project,
        projectsectiontitle,
        stucturedData
    } = data.pim.getFeaturedService;

    return (
        <>
            <SeoMetaTags pim={data.pim} metadata={metadata} structureddata={stucturedData} />
            <ContentWrapper isWide={true}>
                <Header image size="oneLiner" bgImage={backgroundimage} bgColor={backgroundColor}>
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                        vertical="center"
                    >
                        <ContentWrapper
                            className={css.introduction}
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                {title}
                            </Heading>
                            <HighlightedText color="white" borderColor="white">
                                {subtitle}
                            </HighlightedText>
                            <LinkButtonRow>
                                <LinkButton
                                    link={urlBuilder.buildStaticUrl('contact')}
                                    variant="orangeRevert"
                                    text={__("Contact")}
                                />
                                <LinkButton
                                    link={urlBuilder.buildStaticUrl('webinars')}
                                    variant="orangeRevert"
                                    text={__("follow a webinar")}
                                />
                            </LinkButtonRow>
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                {
                    pagecontent && (
                        <Fade duration={2000}><ContentWrapper maxWidth="maxWidthExtrasmall" paddedTopLarge paddedBottom>
                            <RichContent>{pagecontent}</RichContent>
                        </ContentWrapper></Fade>
                    )
                }
                {
                    featuredItems && (
                        <Fade duration={2000}>
                            <ContentWrapper textCenter paddedBottomLarge>
                                <UspGrid>
                                    {featuredItems.map((featuredItem, index) => (
                                        <UspItem
                                            key={index}
                                            altTitle={title}
                                            uspText={featuredItem.itemTxt}
                                            uspImg={featuredItem.itemPicture}
                                        />
                                    ))}

                                    <LinkButton
                                        text={__("Contact")}
                                        link={urlBuilder.buildStaticUrl('contact')}
                                        uppercase={false}
                                    />
                                </UspGrid>
                            </ContentWrapper>
                        </Fade>
                    )
                }
            </ContentWrapper>
            {
                quote && (
                    <Fade duration={2000}>
                        <ContentWrapper isWide={true} bgColor="bgColorOrange">
                            <ContentWrapper maxWidth="maxWidthExtrasmall" paddedTop paddedBottom textCenter>
                                <Quote size="large" color="white"
                                    quote={quote}
                                    quoteBy={quotesignedby}
                                />
                            </ContentWrapper>
                        </ContentWrapper>
                    </Fade>
                )
            }
            <ContentWrapper isWide={true} paddedTop>
                {
                    pagecontent2 && (
                        <Fade duration={2000}>
                            <ContentWrapper paddedTop paddedBottom>
                                <RichContent>{pagecontent2}</RichContent>
                            </ContentWrapper > </Fade>
                    )
                }
                {
                    blueBoxes && (
                        <Fade duration={2000}>
                            <ContentWrapper paddedBottom>
                                <InfoBoxGrid>
                                    {blueBoxes.map((blueBox, index) => (
                                        <InfoBoxItem
                                            key={index}
                                            infoText={blueBox.itemTxt}
                                        />
                                    ))}
                                </InfoBoxGrid>
                            </ContentWrapper></Fade>
                    )
                }
                {
                    (project) && (
                        <Fade duration={2000}>
                            <ContentWrapper paddedTop paddedBottom>
                                <h2 className="u-centered">{projectsectiontitle}</h2>
                                <CardGrid>
                                    {project.map((item) => (
                                        <Card
                                            key={item.id}
                                            title={item.projecttitle}
                                            subtitle={item.projectsubtitle}
                                            url={urlBuilder.buildDynamicUrl(
                                                item.id,
                                            )}
                                            image={generateThumbnailUrl(
                                                item.previewimage,
                                                "card_preview_thumbnail"
                                            )}
                                        />
                                    ))}
                                </CardGrid>

                                <div className="u-centered">
                                    <LinkButton
                                        variant="blue"
                                        text={__("All references")}
                                        link={urlBuilder.buildStaticUrl('portfolio')}
                                        uppercase={false}
                                    />
                                </div>
                            </ContentWrapper>
                        </Fade>
                    )
                }
            </ContentWrapper>
        </>
    );
};

export default Page;

export const query = graphql`
    query($id: Int!, $localeLang: String!) {
        pim {
            getFeaturedService(id: $id, defaultLanguage: $localeLang) {
                title
                subtitle
                backgroundColor
                backgroundimage {
                    id
                    fullpath
                }
                pagecontent
                featuredItems {
                    ... on PIM_fieldcollection_item {
                        itemTxt
                        itemPicture {
                            id
                            fullpath
                        }
                    }
                }
                quote
                quotesignedby
                pagecontent2
                blueBoxes {
                    ... on PIM_fieldcollection_blueboxes {
                        itemTxt
                    }
                }

                project {
                    ... on PIM_object_Project {
                    id
                    previewimage {
                        id
                        fullpath
                    }
                    projecttitle
                    projectsubtitle
                    }
                    ... on PIM_object_Projectnew {
                    id
                    previewimage {
                        id
                        fullpath
                    }
                    projecttitle
                    projectsubtitle
                    }
                }
                seotitle
                seodescription
                projectsectiontitle
                stucturedData
                ogimage {
                    id
                    fullpath
                }
            }
        }
    }
`;