import React, { Fragment } from "react";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { graphql, useStaticQuery } from 'gatsby';
import { setPages, setLocale } from "@redux/actions";

import { useDispatch } from "react-redux";

import { LanguageProvider } from "../../util/LanguageProvider";

const Layout = (props) => {
    const { children, pageContext, pageResources } = props;
    const dispatch = useDispatch();



    const { allSitePage } = useStaticQuery(graphql`
        query {
            allSitePage {
                nodes {
                    path
                    context {
                        id
                        localeLang
                    }
                }
            }
        }
    `);


    let pages = {};
    allSitePage.nodes.forEach(({ path, context }) => {
        const identifier = `${context.id}`;
        if (!pages[identifier]) pages[context.id] = {};
        pages[identifier][context?.localeLang] = path;
    });

    dispatch(setPages(pages));
    console.log('pageContext', pageContext);



    return (
        <Fragment>
            <LanguageProvider
                localeLang={pageContext.localeLang ?? "nl_BE"}
                pageId={pageContext.id}
                useLanguageSwitch={pageContext?.config?.useLanguageSwitch}>
                {/* <SEO pageContext={pageContext} /> */}
                <Header />
                <main>{children}</main>
                <Footer parentComponentName={pageResources?.page?.componentChunkName ?? ''} />
            </LanguageProvider>
        </Fragment>
    );
};

export default Layout;
