import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import React, { Fragment } from "react";
import sliderCss from "../../vacancies/ImageSlider/ImageSlider.module.scss";

import { LocalizedLink as Link } from "@components/util/LanguageProvider";
import urlBuilder from "@util/url-builder";

export const canShowExternalPartnerPage = (partnerPageUrl) => {
    return partnerPageUrl && partnerPageUrl !== "";
};

export const PartnerLink = (props) => {
    const { id, partnerPageUrl, techNewtab, className, children } = props;

    const isExternalPartnerPage = canShowExternalPartnerPage(partnerPageUrl);
    const hasPage = !urlBuilder.buildDynamicUrl(id).includes('404') || isExternalPartnerPage;

    if (!hasPage) {
        return (
            <div className={className}>
                {children}
            </div>
        );
    }

    if (isExternalPartnerPage) {
        return (
            <a
                href={partnerPageUrl}
                target={techNewtab ? "_blank" : "_self"}
                rel="noopener noreferrer"
                className={className}
            >
                {children}
            </a>
        );
    }

    return (
        <Link
            to={urlBuilder.buildDynamicUrl(id)}
            className={className}
        >
            {children}
        </Link>
    );
}

const PartnerSliderItem = (props) => {
    const { id, partnerPageUrl, techLogo, techNewtab, text, className } = props;

    return (
        <Fragment>
            {techLogo && (
                <PartnerLink id={id} partnerPageUrl={partnerPageUrl} techNewtab={techNewtab} className={className} key={id}>
                    <div className={sliderCss.itemParent}>
                        <div className={sliderCss.item}>
                            <img
                                alt={text}
                                className={sliderCss.imagebw}
                                src={generateThumbnailUrl(
                                    techLogo,
                                    "carousel_thumbnail"
                                )}
                            />
                        </div>
                    </div>
                </PartnerLink>
            )}
        </Fragment>

    );

}

export default PartnerSliderItem;
