
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import ServicesList from "@components/homepage/sections/ServicesList/ServicesList";
import LinkButton from "@components/common/LinkButton/LinkButton";
import LinkButtonRow from "@components/common/LinkButtonRow/LinkButtonRow";
import urlBuilder from "@util/url-builder";


import { __ } from "@i18n/util";
import css from "@styles-pages/our-process.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { checkButton } from '@util/check-button';
import { SeoMetaTags } from "../components/util/SOE/SOE";
import Fade from '@components/util/Fade';

import { useEnableFlag } from '@components/util/LanguageHooks';

import { useUrlBuilder } from "@util/url-builder";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getOurprocesConfig',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const Proces = (props) => {

    const { data } = props;
    const pim = data.pim;
    
    const filteredGetServices = useEnableFlag(pim.getServices);

    console.log('pim services', pim.getServices);
    console.log(filteredGetServices, 'filteredGetServices in proces.js');

    const items = filteredGetServices.edges.map(({ node }) => ({
        title: node.pageIntroTitle,
        subtitle: node.pageIntroSubtitle,
        richContent: node.pageIntroHtml,
        urlKey: "/",
    }));

    const services = items.map(({ title }, index) => ({
        title: title,
        anchor: `#service_${index + 1}`,
    }));

    const { button1URL, button1txt, button2URL, button2txt, stucturedData} = pim.getOurprocesConfig;

    const isBtn1 = checkButton(button1txt, button1URL);
    const isBtn2 = checkButton(button2txt, button2URL);

    const { buildLocalizedStaticUrl } = useUrlBuilder();

    return (
        <>
            <SeoMetaTags pim={pim} metadata={metadata} structureddata={stucturedData}/>
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <ContentWrapper
                            className={css.introduction}
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                {__("Our process")}
                            </Heading>
                            <HighlightedText color="white">
                                {__`Our approach has been crystallized into 6 D's, a pragmatic approach in which we work together closely to not only let your project succeed, but to also let it grow to perhaps previously unknown heights.`}
                            </HighlightedText>

                            {(isBtn1 || isBtn2) && (
                                <LinkButtonRow>
                                    {isBtn1 && (
                                        <LinkButton
                                            link={buildLocalizedStaticUrl(button1URL)}
                                            variant="orangeRevert"
                                            text={button1txt}
                                        />
                                    )}

                                    {isBtn2 && (
                                        <LinkButton
                                            link={buildLocalizedStaticUrl(button2URL)}
                                            variant="orangeRevert"
                                            text={button2txt}
                                        />
                                    )}
                                </LinkButtonRow>
                            )}
                        </ContentWrapper>

                        <ContentWrapper
                            isWide={true}
                            className={css.anchorLinks}
                        >
                            {services.map(({ title, anchor }, index) => (
                                <AnchorLink
                                    className={css.anchorLink}
                                    href={anchor}
                                    key={index}
                                >
                                    {title}
                                </AnchorLink>
                            ))}
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                    <ContentWrapper maxWidth="maxWidthLarge" paddedTop>
                        <ServicesList items={items} />
                    </ContentWrapper>
            </ContentWrapper>
        </>
    );
};

export default Proces;

export const query = graphql`
    query($localeLang: String!) {
        pim {
            getServices: getPageListing(
                defaultLanguage: $localeLang, filter: "{\"$or\": [{\"pageReference\": \"drive\"}, {\"pageReference\": \"discover\"}, {\"pageReference\": \"define\"}, {\"pageReference\": \"develop\"}, {\"pageReference\": \"deliver\"}, {\"pageReference\": \"design\"}]}") {
                edges {
                    node {
                        englishVersion
                        pageIntroHtml
                        pageIntroTitle
                        pageIntroSubtitle
                    }
                }
            },
            getOurprocesConfig(id: 245, defaultLanguage: $localeLang) {
                button1URL
                button1txt
                button2URL
                button2txt

                seotitle
                seodescription
                stucturedData
                ogimage {
                    id
                    fullpath
                }
            }
        }
    }`;
