import LinkButton from "@components/common/LinkButton/LinkButton";
import { __ } from "@i18n/util";
import classNames from "classnames";
import React from "react";
import ContentWrapper from "../../../common/ContentWrapper/ContentWrapper";
import Heading from "../../../common/Heading/Heading";
import css from "./CallToAction.module.scss";
import { useLocation } from '@reach/router';
import urlBuilder from "../../../../util/url-builder";

import { useUrlBuilder } from "@util/url-builder";


const CallToAction = () => {
    const location = useLocation();
    const hideComponent = location.pathname.includes("/contact")
                        || location.pathname.includes("/vacancy-contact")
                        || location.pathname.includes("/jobs");

    const { buildLocalizedStaticUrl } = useUrlBuilder();

    return (
        <ContentWrapper isWide paddedTop paddedBottom className={classNames(hideComponent ? css.hide : css.cata)}>
            <Heading
                className={classNames(css.title)}
                isSemantic={false}
                variant="h4"
                color="white"
            >
                {
                    __("Contact us today")
                }
            </Heading>
            <p className={css.subTitle}>
                {
                    __("Do you have a project you would like to trust to a professional partner that will guide you? Or do you have a question about E-commerce or datamanagement? Contact us! We're looking forward to meeting you!")
                }
            </p>
            <LinkButton
                link={buildLocalizedStaticUrl("/contact")}
                variant="orange"
                text={__("Contact")}
                uppercase={false}
                marginSides
            />
            {
                (false) &&
                (
                    <LinkButton
                        link={buildLocalizedStaticUrl("/newsletter")}
                        variant="orange"
                        text={__("Subscribe for newsletter")}
                        uppercase={false}
                        marginSides
                    />
                )

            }
        </ContentWrapper>
    )
}

export default CallToAction
